div.notification__wrapper-375bz[data-code] {
	padding: 16px;

	border-radius: 20px;
}

.notification__icon-3SzZl {
	margin-right: 16px;
}

.notice_container-2j04S {
	padding: 17px 17px 17px 58px;

	border-radius: 20px;
}

.notice_icon-1eG_Y {
	top: 26px;
}

@media screen and (max-width: 600px) {
	div.notification__wrapper-375bz[data-code] {
		padding: 12px;
	}

	.notification__icon-3SzZl {
		margin-right: 12px;
	}
}
