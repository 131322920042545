.root-19PY7 {
	border-radius: 20px;

	box-shadow: 4px 4px 18px 0 rgba(0, 0, 0, 0.06);
}

.root-19PY7:not(.root_loyalty-3AW24):before {
	content: '';

	width: 100%;
	padding-top: 47.95%;
}

.root_seats-1B7Or:before {
	background: url('https://cdn.websky.aero/content/frontend/images/IO/CardExtra/seats.jpg') no-repeat center / cover;
}

.root_baggage-1VnZE:before {
	background: url('https://cdn.websky.aero/content/frontend/images/IO/CardExtra/baggage.jpg') no-repeat center / cover;
}

.root_meal-29T6G:before {
	background: url('https://cdn.websky.aero/content/frontend/images/IO/CardExtra/meal.jpg') no-repeat center / cover;
}

.root_insurance-1I1YJ:before {
	background: url('https://cdn.websky.aero/content/frontend/images/IO/CardExtra/insurance.jpg') no-repeat center /
		cover;
}

.root_insurance-1I1YJ[data-insurance-code='VZR_CLASSIC']:before {
	background: url('https://cdn.websky.aero/content/frontend/images/IO/CardExtra/foreign-insurance.jpg') no-repeat
		center / cover;
}

.root_businessLounge-3_HFW:before {
	background: url('https://cdn.websky.aero/content/frontend/images/IO/CardExtra/business-lounge.jpg') no-repeat center /
		cover;
}

.root_transfer-Xt_oy:before {
	background: url('https://cdn.websky.aero/content/frontend/images/IO/CardExtra/aeroexpress.jpg') no-repeat center /
		cover;
}

.root_priorityBoarding-2Rehy:before {
	background: url('https://cdn.websky.aero/content/frontend/images/IO/CardExtra/priority-boarding.jpg') no-repeat
		center / cover;
}

.root-19PY7 .header-2-kEp {
	min-height: auto;
	padding-top: 16px;

	background: transparent;
}

.header__title-tGNRF {
	color: var(--brand-1-0);
	font: var(--medium-1-desktop-bold);
}

.root-19PY7 .header__icon-9OEHD {
	display: none;
}

.description-13xRD {
	font: var(--normal-desktop);
}

button.button_add-3XCFA {
	background: var(--brand-3-0);
}

button.button_add-3XCFA:hover {
	background: var(--brand-3--1);
}

.modal__root-3nczg .modal__header-22KH7 {
	border-radius: 20px 20px 0 0;
}

@media screen and (max-width: 600px) {
	.modal__root-3nczg .modal__header-22KH7 {
		border-radius: 8px 8px 0 0;
	}
}
