.registeredPassenger__wrapper-p08YI {
	box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.06);
}

.registeredPassenger__header-2IiwA {
	padding: 22px 20px;

	border-radius: 0;

	background: var(--white);
}

.sendToEmail__text-25C0A {
	color: var(--text-link);
	font-size: 13px;
	font-weight: 500;
	line-height: 1.4;
}

.registeredPassenger__actionsButtons-1HKzT {
	margin-left: 0;
}

@media screen and (max-width: 600px) {
	.registeredPassenger__wrapper-p08YI {
		border-radius: 4px;
	}

	.registeredPassenger__header-2IiwA {
		padding: 22px 20px;

		border-radius: 0;
	}

	.controls__container-EHmcK {
		flex-direction: column;
		align-items: flex-start;
		grid-gap: 6px;
		gap: 6px;
	}
}
