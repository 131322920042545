/* stylelint-disable */

.recommend__wrapper-dXW-I,
.airplane-1V1Xy,
.classInfo-1fLSJ,
.classInfo-1fLSJ img,
.seat-1RrX2,
button.passengers__button-463qa,
button.controlsButton-2FucS {
	border-radius: 0;
}

.seatMap_row-1wWbh button.seat-1RrX2[type='button'] {
	width: 44px;
	height: 44px;
	max-width: 44px;
	box-sizing: border-box;
	border-radius: 5px;
}

.seatMap_wings-1bPLb::before,
.seatMap_wings-1bPLb::after {
	background: var(--bg-elements);
}

button.seat-1RrX2 .number-OGkdN {
	color: var(--text-mid);
}

button.passengers__button-463qa {
	background: var(--brand-3-0);
}

button.passengers__button-463qa:hover {
	background: var(--brand-3--1);
}

button.passengers__button-463qa:active {
	background: var(--brand-3--2);
}

.passengers__button-463qa.passengers__button_clear-2NdoF {
	color: var(--text-light);

	background: var(--white);
}

.passengers__button-463qa.passengers__button_clear-2NdoF:hover,
.passengers__button-463qa.passengers__button_clear-2NdoF:active {
	background: var(--seat-unavailable);
}

.seatMap_row-1wWbh button.seat-1RrX2.seat_occupied-2T-uX {
	border: 3px solid var(--brand-1-0);

	background: var(--white);
	box-sizing: border-box !important;
}

button.seat_preferred-1v8oA.seat_occupied-2T-uX {
	border: 3px solid var(--seat-extra);

	background: var(--white);
}

button.seat_business-ukrzO.seat_occupied-2T-uX {
	border: 3px solid var(--seat-business);

	background: var(--white);
}

button.seat-1RrX2.seat_occupied-2T-uX:hover {
	background: var(--seat-hover);
	border: 3px solid var(--seat-hover);
}

button:not(.seat_unavailable-f1j7l).seat_business-ukrzO:before,
.seat_business-ukrzO .title-I9ks5:after {
	content: '';

	position: absolute;
	top: 3px;
	left: 22px;

	width: 12px;
	height: 8px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9IiM0ZDRkNGQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNiAwTDEyIDVIOFY4SDRWNUgwTDYgMFoiIGZpbGw9IiM0ZDRkNGQiLz4KPC9zdmc+Cg==) no-repeat 50% 50% / contain;

	transform: translateX(-50%);
}

button.seat_business-ukrzO:hover:before {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEyIDgiIGZpbGw9IiNmZmZmZmYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNiAwTDEyIDVIOFY4SDRWNUgwTDYgMFoiIGZpbGw9IiNmZmZmZmYiLz4KPC9zdmc+Cg==) no-repeat 50% 50% / contain;
}

button.seat_business-ukrzO.seat_occupied-2T-uX:before {
	content: normal;
	content: initial;
}

button.seat-1RrX2.seat_occupied-2T-uX .number-OGkdN {
	color: var(--brand-1-0);
}

.seatMap_row-1wWbh button.seat-1RrX2.seat_combinedRight-2znmY {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.seatMap_row-1wWbh button.seat-1RrX2.seat_combinedLeft-2559X {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

button.seat-1RrX2.seat_free-3Wx62.seat_occupied-2T-uX {
	border-color: var(--seat-free);
}

button.seat-1RrX2.seat_free-3Wx62.seat_occupied-2T-uX:hover {
	background: var(--seat-free);
}

button.seat-1RrX2.seat_free-3Wx62.seat_occupied-2T-uX .number-OGkdN {
	color: var(--seat-free);
}

.seat_business-ukrzO .title-I9ks5:before,
.seat_preferred-1v8oA .title-I9ks5:before,
.seat_economy-3Bd3r .title-I9ks5:before {
	border-radius: 5px;
}

button.seat-1RrX2.seat_occupied-2T-uX.seat-1RrX2.seat_confirmed-eYS-s {
	border: none;

	background: var(--brand-1-0);
}

button.seat-1RrX2.seat_occupied-2T-uX.seat-1RrX2.seat_confirmed-eYS-s .number-OGkdN {
	color: var(--white);
}

.seat_occupied-2T-uX.seat-1RrX2:not(.seat_confirmed-eYS-s):hover:after {
	top: 0;

	width: 20px;
	height: 20px;

	background-size: cover;
}

.seat-1RrX2.seat_recommend-1FWIx,
div.tooltip-2cv9s {
	background: var(--brand-1-0);
}

.seat-1RrX2.seat_recommend-1FWIx:not(.seat_recommendCurrent-3S0DW) .number-OGkdN {
	color: var(--white);
}

.seat-1RrX2.seat_recommend-1FWIx.seat_recommendCurrent-3S0DW {
	box-sizing: border-box;

	border: 3px solid var(--brand-1-0);

	background: var(--white);
}

.seat-1RrX2.seat_recommend-1FWIx.seat_recommendCurrent-3S0DW .number-OGkdN,
.seat-1RrX2.seat_recommend-1FWIx.seat_recommendCurrent-3S0DW:hover .number-OGkdN {
	color: var(--brand-1-0) !important;
}

.item-1n2E_ {
	border-radius: 0;
	border: 1px solid transparent;
}

.item-1n2E_.item_recommend-2T_gP {
	background: var(--white);
}

.avatar-3EWf7 {
	font: var(--normal-desktop-accent);
	background-color: var(--bg-fill);
	color: var(--text-light);
}

.item-1n2E_.item_recommend-2T_gP .avatar-3EWf7 {
	color: var(--text-light);

	background: var(--bg-fill);
}

.money-2ZuLc + div {
	margin-top: 3px;
}

.money-2ZuLc,
.item-1n2E_.item_recommend-2T_gP .money-2ZuLc,
.item-1n2E_.item_recommend-2T_gP .select-IPJPS,
.item-1n2E_.item_active-1NZq- .money-2ZuLc,
.item-1n2E_.item_active-1NZq- .select-IPJPS {
	color: var(--brand-1-0);
}

.item-1n2E_ svg,
.item-1n2E_.item_active-1NZq- svg,
.item-1n2E_.item_recommend-2T_gP svg {
	color: var(--text-pale);
}

.item-1n2E_ .payload-375jO:not(.select-IPJPS),
.passengerName-1rqgg {
	color: var(--text-base);
	font-weight: 500;
}

.item-1n2E_.item_active-1NZq- .avatar-3EWf7,
.item-1n2E_.item_recommend-2T_gP.item_active-1NZq- .avatar-3EWf7 {
	color: var(--white);

	background: var(--brand-1-0);
}

.item-1n2E_.item_recommend-2T_gP:hover .avatar-3EWf7 {
	color: var(--white);

	background: var(--brand-1--1);
}

.item-1n2E_.item_recommend-2T_gP.item_active-1NZq-,
.item-1n2E_.item_active-1NZq- {
	border: 1px solid var(--brand-1-0);

	background: var(--white);
}

.item-1n2E_.item_recommend-2T_gP:hover {
	background: var(--brand-1-0);
}

.item-1n2E_.item_recommend-2T_gP:hover * {
	color: var(--white);
}

.item-1n2E_:not(.item_recommend-2T_gP):hover,
.item-1n2E_:not(.item_recommend-2T_gP.item_active-1NZq-):hover {
	border: 1px solid var(--brand-1-0);
}

.item-1n2E_:not(.item_recommend-2T_gP):hover .avatar-3EWf7,
.item-1n2E_:not(.item_recommend-2T_gP.item_active-1NZq-):hover .avatar-3EWf7 {
	color: var(--white);

	background: var(--brand-1-0);
}

.item-1n2E_:not(.item_recommend-2T_gP):hover .select-IPJPS,
.item-1n2E_:not(.item_recommend-2T_gP.item_active-1NZq-):hover .select-IPJPS {
	color: var(--brand-1-0);
}

.item-1n2E_.item_recommend-2T_gP.item_active-1NZq-:hover {
	border: 1px solid var(--brand-3-0);

	background: var(--brand-3-0);
}

.item-1n2E_.item_recommend-2T_gP.item_active-1NZq-:hover .avatar-3EWf7 {
	background: none;
}

button.controlsButton-2FucS {
	background: var(--brand-3-0);
	border-radius: 30px;
	padding: 10px 24px;
	font: var(--normal-desktop-bold);
}

button.controlsButton-2FucS:hover {
	background: var(--brand-3-1);
}

button.controlsButton-2FucS:active {
	background: var(--brand-3--2);
}

.disclaimer__wrp-34JBW {
	position: relative;

	padding: 10px 10px 10px 48px;

	border-radius: 20px;
	border: 1px solid var(--line-separator);
}

.disclaimer__wrp-34JBW p {
	color: var(--text-light);
	font: var(--normal-desktop-accent);
}

.disclaimer__wrp-34JBW:before {
	position: absolute;
	top: 10px;
	left: 14px;

	width: 16px;
	height: 16px;
	content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMCAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDEwLjc2NTFDMCA1LjI0NTE0IDQuNDggMC43NjUxMzcgMTAgMC43NjUxMzdDMTUuNTIgMC43NjUxMzcgMjAgNS4yNDUxNCAyMCAxMC43NjUxQzIwIDE2LjI4NTEgMTUuNTIgMjAuNzY1MSAxMCAyMC43NjUxQzQuNDggMjAuNzY1MSAwIDE2LjI4NTEgMCAxMC43NjUxWk0xMSA5Ljc2NTE0VjE1Ljc2NTFIOVY5Ljc2NTE0SDExWk05Ljk5OTk5IDE4Ljc2NTFDNS41ODk5OSAxOC43NjUxIDEuOTk5OTkgMTUuMTc1MSAxLjk5OTk5IDEwLjc2NTFDMS45OTk5OSA2LjM1NTEzIDUuNTg5OTkgMi43NjUxMyA5Ljk5OTk5IDIuNzY1MTNDMTQuNDEgMi43NjUxMyAxOCA2LjM1NTEzIDE4IDEwLjc2NTFDMTggMTUuMTc1MSAxNC40MSAxOC43NjUxIDkuOTk5OTkgMTguNzY1MVpNMTEgNS43NjUxM1Y3Ljc2NTEzSDlWNS43NjUxM0gxMVoiIGZpbGw9IiNCM0IzQjMiLz4KPC9zdmc+Cg==);
}

.sunInfo-1Jj_G:last-of-type {
	color: var(--text-light);
}

.sunInfo-1Jj_G:first-of-type {
	color: var(--text-base);
	font-weight: 600;
}

.sunInfo__inner-2OII0 {
	color: var(--text-light);
	font: var(--normal-desktop);
}

button.segment-2aTwN {
	border-radius: 30px;
	padding: 12px 43px;
	font: var(--medium--1-desktop-accent);
	color: var(--brand-1-0);
}

button.segment-2aTwN:not(.segment_active-LJa5d) {
	background-color: var(--brand-1-5);
}

.airplane__name-21nND a {
	color: var(--text-light);
	font: var(--small-desktop);
	text-decoration: none;
}

.price__wrapper-2wq5Y {
	color: var(--brand-1-0);
}

.seat__info-2S-qR {
	display: none;
}

.seat-1RrX2 {
	margin-bottom: 0;
	border-bottom: 1px solid var(--line-separator);
}

.image-2lYXg {
	display: none;
}

.seatsInfo__description-2abuI {
	color: var(--text-mid);
	font: var(--normal-desktop);
}

.amenities__item-3qu5Q {
	color: var(--text-pale);
}

.checked-3L5u-,
.recommend-1--BY,
.disclaimer__icon-1-lCW {
	display: none;
}

@media screen and (max-width: 600px) {
	button.controlsButton-2FucS {
		height: unset;
		min-height: unset;
	}
}

/* stylelint-enable */

@media screen and (max-width: 600px) {
	.seat_business-ukrzO .title-I9ks5:after {
		top: 12px;
	}

	.seat__info-2S-qR {
		display: flex;
	}

	.seat-1RrX2 {
		margin-bottom: 12px;
	}
}
