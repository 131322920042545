.toolbar__container-143iY {
	box-shadow: 0 -7px 20px rgba(0, 0, 0, 0.03);
}

button.toolbar__backButton-zX-jj,
button.toolbar__backButton-zX-jj:hover {
	padding: 0;

	color: var(--brand-1-0);
	font: var(--medium--1-desktop-accent);

	background: transparent;
}

button.toolbar__backButton-zX-jj svg {
	display: none;
}

.backButton__wrapper-339zR {
	padding: 0;

	color: var(--brand-1-0);
	font: var(--medium--1-desktop-accent);

	background: transparent;
}

button.toolbar__backButton-zX-jj:before {
	display: block;
	min-width: 40px;
	width: 40px;
	height: 40px;
	margin-right: 10px;

	border-radius: 40px;

	color: var(--brand-1-0);

	background: var(--brand-1-5);
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0yMCAxMUg3LjgzTDEzLjQyIDUuNDFMMTIgNEw0IDEyTDEyIDIwTDEzLjQxIDE4LjU5TDcuODMgMTNIMjBWMTFaIiBmaWxsPSIjMDE3NkZFIi8+Cjwvc3ZnPgo=);
	background-repeat: no-repeat;
	background-position: center;
	content: '';
}

button.toolbar__button-26LjS {
	border-radius: 30px;

	color: var(--bg-white);
	font: var(--medium-desktop-accent);

	background: var(--brand-3-0);
}

button.toolbar__button-26LjS:hover {
	background: var(--brand-3-1);
}

.toolbar__price-2Oh63 {
	font: var(--medium-1-desktop-accent);
	color: var(--text-base);
}

@media screen and (max-width: 600px) {
	.toolbar__container-143iY {
		display: flex;
		align-items: center;
		padding: 12px 0;
	}

	.toolbar__inner-3-f41 {
		width: 100%;
	}

	.toolbar__actions-3zmhK {
		width: auto;
	}

	.mobileToolbar__wrapper-3GieF {
		display: flex;
		align-items: center;
		padding: 12px;

		background: var(--bg-white);
	}

	button.toolbar__button-26LjS {
		padding: 10px 24px;
		height: unset;
		min-height: unset;

		font: var(--normal-desktop-bold);
	}

	button.toolbar__backButton-zX-jj,
	button.toolbar__backButton-zX-jj:hover,
	button.toolbar__backButton-zX-jj:active {
		font: var(--normal-desktop-bold);
	}
}
