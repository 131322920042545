

.webRegistration__conditions-20gMM h2 {
	font-size: 18px;
}

.webRegistration__conditions-20gMM ul {
	padding: 20px 30px;

	list-style-type: disc;
}

.webRegistration__conditions-20gMM li {
	padding: 5px 0;
}

.webRegistration__conditions-20gMM p {
	padding-bottom: 15px;
}
