.orderCard__container-13B2W.orderCard__typeReview-1rBYy button {
	height: 39px;
}

.orderPreview__root-7DP_k {
	border-radius: 20px 0 0 20px;

	background-image: none !important;
}

.orderPreview__overlay-3AMAk,
.orderBooking__securityCode-32vjI,
.orderBooking__price-2o4X3 {
	display: none;
}

.orderCard__root-2jAo1 {
	border-radius: 0 20px 20px 0;

	color: var(--text-mid);
	font: var(--medium--1-desktop-accent);
}

.commonItem__root-3qXKP {
	flex: 1;
	padding: 12px 16px;
}

.orderCard_buttons-3r3GV,
.commonItem__root-3qXKP:not(:first-child) {
	border-color: var(--bg-elements);
}

.commonItem__root-3qXKP.orderCardControls__root-3jJIb {
	color: var(--text-mid);
}

.commonItem__icon-318Ae {
	margin-right: 12px;

	color: var(--text-base);
}

.orderBooking__root-4F0fl.orderBooking__typeReview-23O2V .orderBooking__orderCode-1A6f5 {
	color: var(--text-base);
	font: var(--medium-1-desktop-bold);
}

.orderBooking__root-4F0fl.orderBooking__typeAccount-3tDfz .orderBooking__icon-3HNfy {
	color: var(--success-icon);
}

button.orderBooking__download-3Pmm7 svg {
	display: none;
}

button.orderChange__button-1rYOg.orderChange__button-1rYOg {
	background: var(--brand-1-5);
}

.paymentStatus__root-2YEga {
	font-weight: 400;
}

.paymentStatus__root-2YEga,
.paymentStatus__icon-1a0f3 {
	color: var(--error-icon);
}

.orderStatus__confirmed-3SavW .orderStatus__icon-1v-dT {
	color: var(--success-icon);
}

.orderStatus__root-35nVF.orderStatus__typeReview-CNkjg.orderStatus__confirmed-3SavW {
	color: var(--success-text);
}

.orderPreview__city-3IgE_ {
	display: flex;
	flex-direction: column;
	align-items: center;
	grid-gap: 20px;
	gap: 20px;

	font: var(--medium-desktop-bold);
}

.orderPreview__mainCity-4k7Ed {
	font: var(--big--1-desktop-bold);
}

.orderPreview__city-3IgE_:not(.orderPreview__mainCity-4k7Ed):before {
	content: '';

	width: 20px;
	height: 20px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTS0yLjY3MDI5ZS0wNSA5LjAzNDAzTDEwLjQ1NDcgMTYuODc1TDIwIDMuMTI0OTZMLTIuNjcwMjllLTA1IDkuMDM0MDNaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K) center / contain;
}

.orderPreview__period-1TX4B {
	font: var(--medium-desktop);
}

button.deleteOrderModal-2WMVn {
	font-weight: 400;
}

.orderCard__container-13B2W.orderCard__typeAccount-1MHc4 {
	box-shadow: 4px 4px 18px 0 #0000000f;
}

.orderCard__container-13B2W.orderCard__typeAccount-1MHc4 .orderCard__root-2jAo1 {
	border-radius: 0 10px 10px 0;

	box-shadow: none;
}

.orderCard__container-13B2W.orderCard__typeAccount-1MHc4 .orderCard__preview-2NrvD,
.orderCard__container-13B2W.orderCard__typeAccount-1MHc4 .orderCard__preview-2NrvD > div {
	border-radius: 10px 0 0 10px;
}

@media (max-width: 768px) {
	.orderCard__container-13B2W.orderCard__typeAccount-1MHc4 .orderCard__root-2jAo1 {
		border-radius: 0 0 10px 10px;
	}

	.orderCard__container-13B2W.orderCard__typeAccount-1MHc4 .orderCard__preview-2NrvD,
	.orderCard__container-13B2W.orderCard__typeAccount-1MHc4 .orderCard__preview-2NrvD > div {
		border-radius: 10px 10px 0 0;
	}
}

@media screen and (max-width: 600px) {
	.orderPreview__root-7DP_k,
	.orderCard__root-2jAo1 {
		border-radius: 0;
	}

	.orderBooking__root-4F0fl.orderBooking__typeReview-23O2V span.orderBooking__securityCode-32vjI,
	.orderBooking__root-4F0fl.orderBooking__typeReview-23O2V span.orderBooking__price-2o4X3 {
		display: none;
	}

	.orderCard__container-13B2W.orderCard__typeReview-1rBYy .orderCard__root-2jAo1:after {
		display: none;
	}

	.commonItem__root-3qXKP.commonItem__rootReview-IQJ9m:first-of-type {
		padding-top: 12px;
	}
}
