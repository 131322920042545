

.labelIn-tfa6K .input-1JVzf {
	border-radius: var(--input-border-radius);

	font: var(--medium--1-desktop);
}

.labelIn-tfa6K label.label-2shvs {
	left: 2px;

	font-family: var(--font);
}

.labelIn-tfa6K p {
	font: var(--small-desktop);
}
