

div.fareGroup-3bl-l {
	border-radius: 20px;

	box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.06);
}

.fareGroupGrid__upgradeOffer-3zK9_ {
	display: none;
}

button.fareButton__root-Dd5aM,
div > button.fareButton__selected-229gy {
	border-radius: 100px !important;
}

div.fareGroup__recommend-3so2r {
	border: 2px solid var(--brand-2-0) !important;
}

div.fareGroup_white-OTgji div.keyFeature-2oVPM,
div.fareGroup_white-OTgji.fare_business-3LRAC div.keyFeature-2oVPM {
	border-radius: 15px;

	background-color: var(--brand-2-0);
}

li.option-3R9Pv span.option_title-3PP-f {
	color: var(--text-mid);

	font: var(--normal-desktop);
}

.option-3R9Pv.fareOption_small-1DHEm.fareOption_paid-u6zkv {
	color: var(--seat-unavailable);
}

li.option-3R9Pv span.option_value-3Tms-,
li.option-3R9Pv.option_unavailable-31Kvx span.option_title-3PP-f,
li.option-3R9Pv.option_unavailable-31Kvx span.option_value-3Tms- {
	color: var(--text-pale);
}

li.option-3R9Pv.option_free-xRGM0 span.option_value-3Tms- {
	color: var(--brand-2-0);
}

.fareOption-1rf2r {
	border-radius: 20px;

	color: var(--bg-white);

	background: var(--brand-2-0);
}

.fareOption-1rf2r .fareOption_icon-3k8ck {
	margin-bottom: 4px;

	color: var(--bg-white);
}

div.fareOption-1rf2r.fareOption_paid-u6zkv,
div.fareGroup_white-OTgji div.keyFeature__paid-2VPft,
div.fareGroup_white-OTgji.fare_business-3LRAC div.keyFeature__paid-2VPft,
div.fareOption-1rf2r.fareOption_unavailable-24uvZ {
	border-radius: 20px;
	border-color: transparent;

	opacity: 1;
	background: var(--seat-unavailable);
}

div.fareOption-1rf2r.fareOption_unavailable-24uvZ svg,
div.fareOption-1rf2r.fareOption_paid-u6zkv svg {
	color: var(--text-pale);
}

.icon__container-1xkbs {
	width: 20px;
	height: 20px;

	color: var(--text-pale);
}

.fareOption-1rf2r.fareOption_small-1DHEm {
	padding: 8px 20px;

	border-radius: 20px;

	background: var(--brand-2-0);
}

.fareOption-1rf2r.fareOption_small-1DHEm.fareOption_paid-u6zkv {
	border: none;

	color: var(--text-pale);

	background: var(--seat-unavailable);
}

.fareGroup_white-OTgji .option-3R9Pv {
	padding: 10px 0;

	border-bottom: 1px dashed var(--line-separator);

	color: var(--text-mid);
}

.fareGroup_white-OTgji .option-3R9Pv:not(:last-of-type) {
	border-bottom: 1px dashed var(--line-separator);
}

button.button-2Uaea {
	margin-top: 12px;
	padding: 8px 12px;

	border-radius: 30px;
}

.fareButton__title-2MqFi {
	font: var(--medium-desktop-accent);
}

.fareButton__money-3S39- {
	font: var(--big--1-desktop-bold);
}

span.header-1A_nM {
	font: var(--medium-1-desktop-old);
}

.price-2gjU7 {
	padding-right: 0;
}

.price-2gjU7:after {
	display: none;
}
