

.aircraft-1KE3b {
	margin-top: 0;
}

.aircraft-1KE3b .image-3KZzG {
	margin-bottom: 0;
}

.segmentFlat__totalDuration-1zyUb {
	display: none;
}

.title-3VRDV {
	color: var(--text-light);
	font: var(--small-mobile);
}

.title-3VRDV img {
	display: none;
}
