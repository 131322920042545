/* stylelint-disable */
.cardWrapper-1UjU-,
.root-3eoh4 {
	border-radius: 20px;

	box-shadow: 4px 4px 18px 0 #0000000f;
}

/* General */
.item-MVm-b {
	height: 325px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	border-radius: 10px;

	background: var(--bg-fill);
}

.flightName-3TH1c {
	color: var(--text-base);
}

.counter-2qv-W {
	margin-top: 25px;
	margin-bottom: 4px;
	padding: 6px 0;

	font-weight: 600;
}

.switcherPrimary-3HCoP.switcherPrimary_active-3n95a:after {
	background: var(--brand-3-0);
}

button.button-139ZI {
	border-radius: 0;
}

.body-279zm {
	padding-top: 0;
}

.name-397Vh {
	font: var(--small-desktop);
	color: var(--text-base);
}

.size-2idek {
	font: var(--small-desktop);
	color: var(--text-light);
}

.value-2B7dM {
	font: var(--medium-1-desktop-bold);
}

.measurement-wEV6d {
	font: var(--normal-desktop);
}

.baggageListPrice-1FCdp {
	font: var(--small-desktop-bold);
	color: var(--text-base);
}

.direction-mOfgh {
	font-weight: 700;
}

.iconWrapper-113oS svg path {
	fill: var(--brand-1-0);
}

.iconWrapper-113oS svg circle {
	fill: var(--brand-1-5);
}

/* Baggage card */
.cardIcon-1fIet {
	background: var(--brand-1-5);
}

.cardIcon-1fIet svg path {
	fill: var(--brand-1-0);
}

span.checkbox_root-2H0Hs.checkbox_rootChecked-22l44 {
	color: var(--brand-1-0);
}

/* Header */
.header-39HON span {
	color: var(--brand-3-0);
}

.header-39HON {
	margin-bottom: 23px;
	height: 30px;

	font: var(--medium-desktop-bold);
}

.baggageBanner-3VvZq {
	display: none; /* Temporarily */
}

.promo_message-2M17- {
	border: 1px solid var(--brand-1-3);
	border-radius: 30px;

	color: var(--text-link);
	font: var(--normal-desktop-accent);

	background: none;
}

/* Included content */
.iconRoot-1vQvm {
	color: var(--brand-1-0);
}

.included_block-be5eC .iconRoot-1vQvm {
	color: var(--brand-3-0);
}

.root-3eoh4:first-of-type .iconWrapper-113oS svg path {
	fill: var(--brand-2-0);
}

.root-3eoh4:first-of-type .included_block-be5eC .iconWrapper-113oS svg path {
	fill: var(--brand-3-0);
}

.handLuggageExcess path {
	color: var(--brand-2-0);

	fill: rgba(231, 238, 255, 0.2) !important;
}

.carryOn-5a9jQ .iconSize-2Od2O {
	color: var(--brand-2-1);
}

.handLuggageExcess path:nth-of-type(2) {
	display: none;
}

.root-3eoh4:first-of-type .iconWrapper-113oS svg rect {
	fill: var(--brand-2-4);
}

.root-3eoh4:first-of-type .included_block-be5eC .iconWrapper-113oS svg rect {
	fill: var(--brand-3-3);
}

.root-3eoh4:first-of-type .iconSize-2Od2O {
	color: var(--brand-2-0);
}

.root-3eoh4:first-of-type .included_block-be5eC .iconSize-2Od2O {
	color: var(--brand-3-0);
}

.root-3eoh4:first-of-type .iconRoot-1vQvm {
	height: 130px;
}

.root-3eoh4:first-of-type .counter-2qv-W {
	background: var(--brand-2-0);
}

.root-3eoh4:first-of-type .included_block-be5eC .counter-2qv-W {
	background: var(--brand-3-0);
}

.root-3eoh4:first-of-type .direction-mOfgh {
	color: var(--brand-2-0);
}

.root-3eoh4:first-of-type .included_block-be5eC .direction-mOfgh {
	color: var(--brand-3-0);
}

.handLuggageExcess-2Xrpz .iconSize-2Od2O {
	color: var(--brand-2-0);
}

.root-3eoh4:last-of-type .iconWrapper-113oS svg path {
	fill: var(--brand-1-0);
}

.root-3eoh4:last-of-type .iconSize-2Od2O {
	color: var(--brand-1-0);
}

.root-3eoh4:last-of-type .iconRoot-1vQvm {
	height: auto;
	padding-top: 10px;
}

.root-3eoh4:last-of-type .counter-2qv-W {
	background: var(--brand-1-0);
}

.root-3eoh4:last-of-type .direction-mOfgh {
	color: var(--brand-1-0);
}

.counterButton-E0oCo {
	color: var(--brand-1-0);

	background: var(--brand-1-5);
}

.counterButton-E0oCo:not(.counterButton_disabled-J6ol9):hover {
	color: #ffffff;

	opacity: 0.7;
	background: var(--brand-1-0);
}

.passengerName-WBGU5 {
	font: var(--medium--1-mobile);
}

.price_wrapper-1Z1FJ {
	font: var(--medium-1-desktop-bold);
	color: var(--text-base);
}

.clear-ro4pQ {
	color: var(--brand-1-0);
	font: var(--normal-desktop-accent);
}

.perFlight-2sG_M {
	color: var(--success-text);
}

.segment-2T8FL {
	font-weight: 600;

	background: var(--brand-1-5);
}

.headerEquipmentItem__selected-2nozf {
	background-color: var(--brand-2-0);
}

.close-32e83 > svg > rect {
	fill: var(--bg-fill);
}

/* Footer */
button.control-1Xpks,
button.cardButton-1RPuZ {
	padding: 0 22px;

	border: none;

	color: var(--white);
	font-weight: 700;
	font-size: 14px;
}

.price-3wpaw {
	font: var(--medium-1-desktop-bold);
	color: var(--text-base);
}

.title-3PxL- {
	font: var(--medium-desktop-bold);
	color: var(--text-base);
}

.flightCount-10c3I {
	background: var(--brand-1-0);
}

.flightDirection-3qkJE {
	color: var(--brand-2-0);
}

.toolPrice-26ypP {
	color: var(--text-mid);
}

.baggage_wrapper-1m7-d {
	background: var(--bg-fill);
}

.modalHeader-1Smhj {
	font: var(--medium-1-mobile-bold);
}

.passengerNumber-19Q7w {
	background: var(--bg-fill);
}

button.backButton-1QPgk {
	color: var(--brand-1-0);

	background: var(--brand-1-5);
}

button.backButton-1QPgk:hover {
	background: var(--brand-1-4);
}

.backButton__wrapper-1Tm9F {
	color: var(--brand-1-0);
}

.included_block-be5eC {
	flex-shrink: unset;
}

.headerEquipmentItem__wrapper-9kKeT {
	background-color: var(--brand-1-5);
}

.equipmentBaggage-eW6gw .counter_wrapper-ncR8i {
	justify-content: flex-end;
}

button.add-2Ilny.add-2Ilny {
	padding: 6px;
}

@media screen and (max-width: 600px) {
	.root-3eoh4 {
		border-radius: 12px;
	}

	.root-3eoh4:last-of-type .header-39HON {
		margin-bottom: 56px;
	}

	.header-39HON {
		font: var(--medium-1-mobile-bold);
	}

	.baggageList-26rn_ {
		display: flex;
	}

	.header-39HON span {
		color: var(--success-icon);
	}

	.size-2idek {
		display: inline;
		display: initial;

		font: var(--small--1-normal);
	}

	.name-397Vh {
		font: var(--small--1-normal);
	}

	.item-MVm-b {
		height: auto;
	}

	.items-1g6yd {
		margin-bottom: 0;
	}

	.mobileToolWrapper-2Rfzn {
		height: 63px;

		background: #ffffff;
	}

	button.mobileButtonСontinue-pu5e8 {
		padding: 10px 24px;

		border-radius: 0;

		color: #ffffff;
		font-weight: 700;
		font-size: 14px;

		background: var(--brand-3-0);
	}

	.mobileSelector-172Bv div {
		color: var(--brand-1-0);
	}

	.mobileSelector__description-YSXZ7 {
		margin-left: 0;
	}

	.perFlight-2sG_M {
		color: var(--text-base);
	}

	.baggage_full-1yYje .baggage__icon_notSelected-2julC.baggage-2prGG:after {
		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNi43ODgyIDEyLjk2MTRIMTQuNTQxNEMxMy4xMzc4IDEyLjk2MTQgMTEuOTk5NyAxNC4xNDgxIDEyIDE1LjYxMjNWMzguMzQwNEMxMiAzOS44MDQ2IDEzLjEzODEgNDAuOTkxNiAxNC41NDE3IDQwLjk5MTZIMTQuODgyOVY0Mi45MTU5QzE0Ljg4MjkgNDMuMjY5NyAxNS4xNTc0IDQzLjU1NjcgMTUuNDk3NCA0My41NTY3SDE4LjQwMzZDMTguNzQyNSA0My41NTY3IDE5LjAxODEgNDMuMjcwMSAxOS4wMTgxIDQyLjkxNTlWNDAuOTkxNkgyOC4yMjNWNDIuOTE1OUMyOC4yMjMgNDMuMjY5NyAyOC40OTc4IDQzLjU1NjcgMjguODM3NSA0My41NTY3SDMxLjc0NEMzMi4wODI5IDQzLjU1NjcgMzIuMzU4MiA0My4yNzAxIDMyLjM1ODIgNDIuOTE1OVY0MC45OTE2SDMyLjY5OUMzNC4xMDIzIDQwLjk5MTYgMzUuMjQwNyAzOS44MDQ5IDM1LjI0MDcgMzguMzQwNFYxNS42MTIxQzM1LjI0MDcgMTQuMTQ4MiAzNC4xMDI2IDEyLjk2MTMgMzIuNjk5IDEyLjk2MTNIMzAuNDUyNFY3LjAzNTY5QzMwLjQ1MjQgNS4zNjIgMjkuMTQ2MyA0IDI3LjU0MTYgNEgxOS42OTg4QzE4LjA5MzggNCAxNi43ODgyIDUuMzYyIDE2Ljc4ODIgNy4wMzU2OVYxMi45NjE0Wk0yOC41MTIyIDEyLjk2MTNIMTguNzI5VjcuMDM1NjlDMTguNzI5IDYuNDc3NzkgMTkuMTY0MyA2LjAyMzc5IDE5LjY5OTMgNi4wMjM3OUgyNy41NDJDMjguMDc2OSA2LjAyMzc5IDI4LjUxMjIgNi40Nzc3OSAyOC41MTIyIDcuMDM1NjlWMTIuOTYxM1pNMTcuNjk0NyAxNy4xOTM5QzE2LjI5MjIgMTcuMTkzOSAxNS4xNTUyIDE4LjMzMDkgMTUuMTU1MiAxOS43MzM0VjIxLjQyNjRIMzIuMDg1NVYxOS43MzM0QzMyLjA4NTUgMTguMzMwOSAzMC45NDg1IDE3LjE5MzkgMjkuNTQ1OSAxNy4xOTM5SDE3LjY5NDdaTTE1LjE1NTIgMzcuNTEwMlYyMy4xMTk1TDI3Ljg1MyAyMy4xMTk1VjI0LjgxMjVIMjkuNTQ2VjIzLjExOTVIMzIuMDg1NVYzNy41MTAySDMzLjc3ODVWMTkuNzMzNEMzMy43Nzg1IDE3LjM5NTggMzEuODgzNSAxNS41MDA4IDI5LjU0NTkgMTUuNTAwOEgxNy42OTQ3QzE1LjM1NzIgMTUuNTAwOCAxMy40NjIyIDE3LjM5NTggMTMuNDYyMiAxOS43MzM0VjM3LjUxMDJIMTUuMTU1MloiIGZpbGw9IiMzN2FhZTEiLz4KPC9zdmc+Cg==) center no-repeat;
	}

	.baggage_full-1yYje .baggage__icon_notSelected-2julC.carryon-1XU_P:after {
		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNCAxMEMyMi42MTAxIDEwIDIxLjI2NzQgMTAuMjAyNiAyMCAxMC41Nzk4VjEwQzIwIDcuNzkwODYgMjEuNzkwOSA2IDI0IDZDMjYuMjA5MSA2IDI4IDcuNzkwODYgMjggMTBWMTAuNTc5OEMyNi43MzI2IDEwLjIwMjYgMjUuMzg5OSAxMCAyNCAxMFpNMTQgMTQuMjAyQzE1LjE0ODIgMTMuMDMwMyAxNi41MDA4IDEyLjA1OTUgMTggMTEuMzQ3M1YxMEMxOCA2LjY4NjI5IDIwLjY4NjMgNCAyNCA0QzI3LjMxMzcgNCAzMCA2LjY4NjI5IDMwIDEwVjExLjM0NzNDMzEuNDk5MiAxMi4wNTk1IDMyLjg1MTggMTMuMDMwMyAzNCAxNC4yMDJWNkgzNkwzNiAxNi43ODQ4QzM3LjI2OTYgMTguODkxOSAzOCAyMS4zNjA3IDM4IDI0VjMySDM0VjI0QzM0IDE4LjQ3NzIgMjkuNTIyOCAxNCAyNCAxNEMxOC40NzcyIDE0IDE0IDE4LjQ3NzIgMTQgMjRWMzJIMTBWMjRDMTAgMjEuMzYwNyAxMC43MzA0IDE4Ljg5MTkgMTIgMTYuNzg0OEwxMiA2SDE0TDE0IDE0LjIwMlpNMzggMzRIMzIuMTgxOEgxNS44MTgySDEwSDlDOC40NDc3MiAzNCA4IDM0LjQ0NzcgOCAzNVY0M0M4IDQzLjU1MjMgOC40NDc3MiA0NCA5IDQ0SDEySDEzSDM1SDM2SDM5QzM5LjU1MjMgNDQgNDAgNDMuNTUyMyA0MCA0M1YzNUM0MCAzNC40NDc3IDM5LjU1MjMgMzQgMzkgMzRIMzhaTTMyIDMyVjI0QzMyIDE5LjU4MTcgMjguNDE4MyAxNiAyNCAxNkMxOS41ODE3IDE2IDE2IDE5LjU4MTcgMTYgMjRWMzJIMzJaTTE0IDM2VjM4SDMwVjQwSDMyVjM4SDM0VjM2SDE0WiIgZmlsbD0iIzM3YWFlMSIvPgo8L3N2Zz4K) center no-repeat;
	}

	.baggage_full-1yYje .baggage-2prGG:after {
		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNi43ODgyIDEyLjk2MTRIMTQuNTQxNEMxMy4xMzc4IDEyLjk2MTQgMTEuOTk5NyAxNC4xNDgxIDEyIDE1LjYxMjNWMzguMzQwNEMxMiAzOS44MDQ2IDEzLjEzODEgNDAuOTkxNiAxNC41NDE3IDQwLjk5MTZIMTQuODgyOVY0Mi45MTU5QzE0Ljg4MjkgNDMuMjY5NyAxNS4xNTc0IDQzLjU1NjcgMTUuNDk3NCA0My41NTY3SDE4LjQwMzZDMTguNzQyNSA0My41NTY3IDE5LjAxODEgNDMuMjcwMSAxOS4wMTgxIDQyLjkxNTlWNDAuOTkxNkgyOC4yMjNWNDIuOTE1OUMyOC4yMjMgNDMuMjY5NyAyOC40OTc4IDQzLjU1NjcgMjguODM3NSA0My41NTY3SDMxLjc0NEMzMi4wODI5IDQzLjU1NjcgMzIuMzU4MiA0My4yNzAxIDMyLjM1ODIgNDIuOTE1OVY0MC45OTE2SDMyLjY5OUMzNC4xMDIzIDQwLjk5MTYgMzUuMjQwNyAzOS44MDQ5IDM1LjI0MDcgMzguMzQwNFYxNS42MTIxQzM1LjI0MDcgMTQuMTQ4MiAzNC4xMDI2IDEyLjk2MTMgMzIuNjk5IDEyLjk2MTNIMzAuNDUyNFY3LjAzNTY5QzMwLjQ1MjQgNS4zNjIgMjkuMTQ2MyA0IDI3LjU0MTYgNEgxOS42OTg4QzE4LjA5MzggNCAxNi43ODgyIDUuMzYyIDE2Ljc4ODIgNy4wMzU2OVYxMi45NjE0Wk0yOC41MTIyIDEyLjk2MTNIMTguNzI5VjcuMDM1NjlDMTguNzI5IDYuNDc3NzkgMTkuMTY0MyA2LjAyMzc5IDE5LjY5OTMgNi4wMjM3OUgyNy41NDJDMjguMDc2OSA2LjAyMzc5IDI4LjUxMjIgNi40Nzc3OSAyOC41MTIyIDcuMDM1NjlWMTIuOTYxM1pNMTcuNjk0NyAxNy4xOTM5QzE2LjI5MjIgMTcuMTkzOSAxNS4xNTUyIDE4LjMzMDkgMTUuMTU1MiAxOS43MzM0VjIxLjQyNjRIMzIuMDg1NVYxOS43MzM0QzMyLjA4NTUgMTguMzMwOSAzMC45NDg1IDE3LjE5MzkgMjkuNTQ1OSAxNy4xOTM5SDE3LjY5NDdaTTE1LjE1NTIgMzcuNTEwMlYyMy4xMTk1TDI3Ljg1MyAyMy4xMTk1VjI0LjgxMjVIMjkuNTQ2VjIzLjExOTVIMzIuMDg1NVYzNy41MTAySDMzLjc3ODVWMTkuNzMzNEMzMy43Nzg1IDE3LjM5NTggMzEuODgzNSAxNS41MDA4IDI5LjU0NTkgMTUuNTAwOEgxNy42OTQ3QzE1LjM1NzIgMTUuNTAwOCAxMy40NjIyIDE3LjM5NTggMTMuNDYyMiAxOS43MzM0VjM3LjUxMDJIMTUuMTU1MloiIGZpbGw9IiMzN2FhZTEiLz4KPC9zdmc+Cg==) center no-repeat;
	}

	.baggage_full-1yYje .carryon-1XU_P:after {
		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNCAxMEMyMi42MTAxIDEwIDIxLjI2NzQgMTAuMjAyNiAyMCAxMC41Nzk4VjEwQzIwIDcuNzkwODYgMjEuNzkwOSA2IDI0IDZDMjYuMjA5MSA2IDI4IDcuNzkwODYgMjggMTBWMTAuNTc5OEMyNi43MzI2IDEwLjIwMjYgMjUuMzg5OSAxMCAyNCAxMFpNMTQgMTQuMjAyQzE1LjE0ODIgMTMuMDMwMyAxNi41MDA4IDEyLjA1OTUgMTggMTEuMzQ3M1YxMEMxOCA2LjY4NjI5IDIwLjY4NjMgNCAyNCA0QzI3LjMxMzcgNCAzMCA2LjY4NjI5IDMwIDEwVjExLjM0NzNDMzEuNDk5MiAxMi4wNTk1IDMyLjg1MTggMTMuMDMwMyAzNCAxNC4yMDJWNkgzNkwzNiAxNi43ODQ4QzM3LjI2OTYgMTguODkxOSAzOCAyMS4zNjA3IDM4IDI0VjMySDM0VjI0QzM0IDE4LjQ3NzIgMjkuNTIyOCAxNCAyNCAxNEMxOC40NzcyIDE0IDE0IDE4LjQ3NzIgMTQgMjRWMzJIMTBWMjRDMTAgMjEuMzYwNyAxMC43MzA0IDE4Ljg5MTkgMTIgMTYuNzg0OEwxMiA2SDE0TDE0IDE0LjIwMlpNMzggMzRIMzIuMTgxOEgxNS44MTgySDEwSDlDOC40NDc3MiAzNCA4IDM0LjQ0NzcgOCAzNVY0M0M4IDQzLjU1MjMgOC40NDc3MiA0NCA5IDQ0SDEySDEzSDM1SDM2SDM5QzM5LjU1MjMgNDQgNDAgNDMuNTUyMyA0MCA0M1YzNUM0MCAzNC40NDc3IDM5LjU1MjMgMzQgMzkgMzRIMzhaTTMyIDMyVjI0QzMyIDE5LjU4MTcgMjguNDE4MyAxNiAyNCAxNkMxOS41ODE3IDE2IDE2IDE5LjU4MTcgMTYgMjRWMzJIMzJaTTE0IDM2VjM4SDMwVjQwSDMyVjM4SDM0VjM2SDE0WiIgZmlsbD0iIzM3YWFlMSIvPgo8L3N2Zz4K) center no-repeat;
	}

	div.overweight__disabled-1-ptH,
	div.overweight__disabled-1-ptH + .service__values-fHUb3,
	div.overweight__disabled-1-ptH + .service__values-fHUb3 + .service__price-2YdKx {
		opacity: 0.7;
	}

	div.overweight__disabled-1-ptH:after {
		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTYiIGhlaWdodD0iNTYiIHZpZXdCb3g9IjAgMCA1NiA1NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMjcuOTk5OCAxNEM0MC44ODY1IDE0IDUxLjMzMzIgMjQuNDQ2NyA1MS4zMzMyIDM3LjMzMzNINTEuMzMzMUg0Ni42NjY1SDM0LjU3NDJDMzQuNjE5NSAzNi41MTkzIDM0LjUyMjkgMzUuNzAwOSAzNC4yODc1IDM0LjkxNzhMMzguNzk0OSAyNy42MzY3TDM1LjMzMjUgMjQuNjAzNEwyOC43MDc0IDMwLjAyOUMyOC40NzI2IDI5Ljk5MTEgMjguMjM2NCAyOS45NjUyIDI3Ljk5OTggMjkuOTUxM1YxOC42NjY3QzE3LjY5MDUgMTguNjY2NyA5LjMzMzE3IDI3LjAyNCA5LjMzMzE3IDM3LjMzMzNINC42NjY1QzQuNjY2NSAyNC40NDY3IDE1LjExMzIgMTQgMjcuOTk5OCAxNEMyNy45OTk4IDE0IDI3Ljk5OTggMTQgMjcuOTk5OCAxNFpNMzIuMjM1NSAzNy4zMzMzSDMyLjIzNTVDMzIuMTU0OSAzOC4yOTIyIDMxLjc3ODEgMzkuMjM0NyAzMS4wOTUzIDQwLjAxNDFDMjkuMzk2OSA0MS45NTI2IDI2LjQ0ODUgNDIuMTQ3NCAyNC41MSA0MC40NDlDMjIuNTcxNCAzOC43NTA2IDIyLjM3NjcgMzUuODAyMiAyNC4wNzUxIDMzLjg2MzZDMjUuMTQ4MyAzMi42Mzg3IDI2LjcyMDUgMzIuMTEgMjguMjIxNyAzMi4zMTUzQzI4LjUyMzYgMzIuMzU2NiAyOC44MjI2IDMyLjQyNzUgMjkuMTE0MSAzMi41Mjg1QzI5LjE2NiAzMi41NDY0IDI5LjIxNzUgMzIuNTY1MyAyOS4yNjg4IDMyLjU4NTFMMzYuODEwOCAyNi40MDg2TDM2LjgxMDggMjYuNDA4N0wzNi44MTA5IDI2LjQwODZMMzEuNjc5OCAzNC42OTczQzMyLjEyMzYgMzUuNTA4MiAzMi4zMTE2IDM2LjQyODEgMzIuMjM1NSAzNy4zMzMzWiIgZmlsbD0iIzM3YWFlMSIvPgo8L3N2Zz4K) center no-repeat;
	}

	div.overweight__active-3V5DX:after {
		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTYiIGhlaWdodD0iNTYiIHZpZXdCb3g9IjAgMCA1NiA1NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMjcuOTk5OCAxNEM0MC44ODY1IDE0IDUxLjMzMzIgMjQuNDQ2NyA1MS4zMzMyIDM3LjMzMzNINTEuMzMzMUg0Ni42NjY1SDM0LjU3NDJDMzQuNjE5NSAzNi41MTkzIDM0LjUyMjkgMzUuNzAwOSAzNC4yODc1IDM0LjkxNzhMMzguNzk0OSAyNy42MzY3TDM1LjMzMjUgMjQuNjAzNEwyOC43MDc0IDMwLjAyOUMyOC40NzI2IDI5Ljk5MTEgMjguMjM2NCAyOS45NjUyIDI3Ljk5OTggMjkuOTUxM1YxOC42NjY3QzE3LjY5MDUgMTguNjY2NyA5LjMzMzE3IDI3LjAyNCA5LjMzMzE3IDM3LjMzMzNINC42NjY1QzQuNjY2NSAyNC40NDY3IDE1LjExMzIgMTQgMjcuOTk5OCAxNEMyNy45OTk4IDE0IDI3Ljk5OTggMTQgMjcuOTk5OCAxNFpNMzIuMjM1NSAzNy4zMzMzSDMyLjIzNTVDMzIuMTU0OSAzOC4yOTIyIDMxLjc3ODEgMzkuMjM0NyAzMS4wOTUzIDQwLjAxNDFDMjkuMzk2OSA0MS45NTI2IDI2LjQ0ODUgNDIuMTQ3NCAyNC41MSA0MC40NDlDMjIuNTcxNCAzOC43NTA2IDIyLjM3NjcgMzUuODAyMiAyNC4wNzUxIDMzLjg2MzZDMjUuMTQ4MyAzMi42Mzg3IDI2LjcyMDUgMzIuMTEgMjguMjIxNyAzMi4zMTUzQzI4LjUyMzYgMzIuMzU2NiAyOC44MjI2IDMyLjQyNzUgMjkuMTE0MSAzMi41Mjg1QzI5LjE2NiAzMi41NDY0IDI5LjIxNzUgMzIuNTY1MyAyOS4yNjg4IDMyLjU4NTFMMzYuODEwOCAyNi40MDg2TDM2LjgxMDggMjYuNDA4N0wzNi44MTA5IDI2LjQwODZMMzEuNjc5OCAzNC42OTczQzMyLjEyMzYgMzUuNTA4MiAzMi4zMTE2IDM2LjQyODEgMzIuMjM1NSAzNy4zMzMzWiIgZmlsbD0iIzM3YWFlMSIvPgo8L3N2Zz4K) center no-repeat;
	}

	.baggage_full-1yYje .service__price-2YdKx,
	.baggage_full-1yYje .service__values-fHUb3 {
		color: var(--brand-1-0);
	}

	.add__button-1aVr1 {
		border: none;

		background: var(--brand-1-5);
	}

	.add__button-1aVr1:after {
		background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy4xMjUgN0MxMy4xMjUgNy40ODMyNSAxMi43MzMyIDcuODc1IDEyLjI1IDcuODc1SDEuNzVDMS4yNjY3NSA3Ljg3NSAwLjg3NSA3LjQ4MzI1IDAuODc1IDdWN0MwLjg3NSA2LjUxNjc1IDEuMjY2NzUgNi4xMjUgMS43NSA2LjEyNUgxMi4yNUMxMi43MzMyIDYuMTI1IDEzLjEyNSA2LjUxNjc1IDEzLjEyNSA3VjdaIiBmaWxsPSIjMzdhYWUxIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNyAxMy4xMjVDNy40ODMyNSAxMy4xMjUgNy44NzUgMTIuNzMzMiA3Ljg3NSAxMi4yNUw3Ljg3NSAxLjc1QzcuODc1IDEuMjY2NzUgNy40ODMyNSAwLjg3NSA3IDAuODc1VjAuODc1QzYuNTE2NzUgMC44NzUgNi4xMjUgMS4yNjY3NSA2LjEyNSAxLjc1TDYuMTI1IDEyLjI1QzYuMTI1IDEyLjczMzIgNi41MTY3NSAxMy4xMjUgNyAxMy4xMjVWMTMuMTI1WiIgZmlsbD0iIzM3YWFlMSIvPgo8L3N2Zz4K) center no-repeat;
	}

	.direction-mOfgh {
		font: var(--small--1-accent);
	}

	.counter-2qv-W {
		padding: 5px 0;

		font: var(--medium--1-desktop-accent);
	}

	.counterButton_value-2s2tk {
		font: var(--medium--1-mobile-accent);
	}

	.category_title-2a9wX {
		font: var(--normal-mobile-accent);
	}

	.category__price-2rqED {
		font: var(--medium-1-desktop-bold);
	}
}
/* stylelint-enable */
