

.flight-1na8M,
.flight-1na8M.flight_selected-3aLPs {
	border-radius: 10px;
}

.flight-1na8M.flight_selected-3aLPs,
.flight-1na8M.readonly-LyKZt {
	border-color: var(--line-separator);
}

div > button.flightPrice-1Wyy4,
div > button.flightPrice-1Wyy4:hover {
	border-radius: 10px;

	color: var(--text-base);
	font: var(--small-mobile);

	background: var(--brand-1-5);
}

.flightPrice__money-3i0qc {
	color: var(--text-base);
	font: var(--medium-1-mobile-bold);
}

.flight-1na8M {
	padding: 0;

	border: unset;

	box-shadow: 4px 4px 18px 0 #0000000f;
}

.segment-HPA8d {
	padding: 16px 12px 24px 12px;
}
