div.paper-1cJZh {
	border-radius: 20px;
}

.header_wrapper-1bg4d {
	max-width: 1100px;
	padding: 40px 0;
	margin: 0 auto;
}

.breadcrumb-28UZJ {
	color: var(--brand-1--1);

	background: var(--brand-1-5);
}

h6.stepTitle_text-3IecS {
	font: var(--big--1-desktop-bold);
}

label.groupTitle-Y5Q8V {
	font-weight: 600;
}

label.reason-113V1 span,
h6.stepTitle_text-3IecS,
label.groupTitle-Y5Q8V,
label.passenger_label-VbRHB span {
	font-family: 'Open Sans', Helvetica, Arial, sans-serif;
}

h6.stepTitle_text-3IecS,
label.groupTitle-Y5Q8V,
label.reason-113V1,
label.passenger_label-VbRHB,
.wrapper_title-1-ua9,
label.formLabel-ugHGo,
.ticket_title-LiSub,
.price_total-3ynqt,
.status_icon-1cizF {
	color: var(--text-base);
}

.reason_label-3omUF.reason_active-2L5JL span:last-of-type {
	color: var(--brand-1--1);
}

.stepTitle_icon-UIl0E {
	position: relative;

	background: var(--brand-1-0);
}

.stepTitle_icon-UIl0E:after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 0;

	margin: 12px;

	border-radius: 50%;

	background: var(--white);
	content: '';
}

.stepTitle_icon-UIl0E svg {
	z-index: 1;

	transform: scale(0.55);
}

.stepTitle_icon-UIl0E svg path {
	fill: var(--brand-1-0);
}

.buttons-1ezNu button.stepButtons_button-2x9XV {
	height: 48px;
	min-width: 160px;

	font: var(--medium--1-desktop-bold);
}

.buttons-1ezNu button.stepButtons_button-2x9XV:disabled {
	color: var(--white);

	background: var(--brand-3-3);
}

.buttons-1ezNu button.stepButtons_button-2x9XV:last-of-type {
	background: var(--brand-3-0);
}

.buttons-1ezNu button.stepButtons_button-2x9XV:last-of-type:hover {
	background: var(--brand-3-1);
}

button.passenger-2qgnu.passenger_active-AGA3f {
	background: var(--brand-1-4);
}

div.status_waiting-3_bQd {
	color: var(--text-base);

	background: var(--warning);
}

.refundInfoModal__button-3HfHE:not(.refundInfoModal__button_no-2qmGf) {
	background: var(--brand-3-0);
}

.refundInfoModal__button-3HfHE:not(.refundInfoModal__button_no-2qmGf):hover {
	background: var(--brand-3-1);
}

.orderRefundStatus__container-YPEAm {
	border-radius: 20px;

	font: var(--medium-1-desktop-bold);

	box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.06);
}

.orderRefundStatus_success-1Jmk2,
.orderRefundStatus_waiting-1Cqc1 {
	color: var(--text-mid);
}

.orderRefundStatus__container-YPEAm.orderRefundStatus_rejected-2hlog {
	border: 1px solid var(--error-stroke-hover);

	color: var(--error-text);

	background: var(--error-bg);
}

.orderRefundStatus__container-YPEAm.orderRefundStatus_processing-1t_1u,
.orderRefundStatus__container-YPEAm.orderRefundStatus_waiting-1Cqc1 {
	border: 1px solid var(--warning-icon);

	background: var(--warning-bg);
}

.orderRefundStatus__title-3kwa5 {
	background: transparent;
}

.orderRefundStatus_rejected-2hlog .orderRefundStatus__title-3kwa5 {
	color: var(--error-text);
}

button.orderRefundStatus__button-1AP27.orderRefundStatus_rejected-2hlog,
button.orderRefundStatus__button-1AP27.orderRefundStatus_processing-1t_1u,
button.orderRefundStatus__button-1AP27.orderRefundStatus_waiting-1Cqc1 {
	padding: 0;
}

.orderRefundStatus_success-1Jmk2 button,
button.orderRefundStatus__button-1AP27.orderRefundStatus_processing-1t_1u,
button.orderRefundStatus__button-1AP27.orderRefundStatus_waiting-1Cqc1 {
	color: var(--white);

	background: var(--brand-3-0);
}

.orderRefundStatus_success-1Jmk2 button:hover,
button.orderRefundStatus__button-1AP27.orderRefundStatus_processing-1t_1u:hover,
button.orderRefundStatus__button-1AP27.orderRefundStatus_waiting-1Cqc1:hover {
	color: var(--white);

	background: var(--brand-3--1);
}

.orderRefundStatus_success-1Jmk2 button:active,
button.orderRefundStatus__button-1AP27.orderRefundStatus_processing-1t_1u:active,
button.orderRefundStatus__button-1AP27.orderRefundStatus_waiting-1Cqc1:active {
	color: var(--white);

	background: var(--brand-3--2);
}

button.orderRefundStatus__button-1AP27.orderRefundStatus_rejected-2hlog {
	color: var(--white);

	background: var(--error-icon);
}

button.orderRefundStatus__button-1AP27.orderRefundStatus_rejected-2hlog:hover {
	color: var(--white);

	background: var(--error-text);
}

@media screen and (max-width: 600px) {
	.orderRefundStatus__container-YPEAm {
		border-radius: 0;
	}
}
