.checkout__services-24Lnr {
	grid-gap: 8px;
	gap: 8px;
	grid-template: repeat(auto-fill, minmax(200px, 1fr)) / repeat(auto-fill, minmax(269px, 1fr));
}

.checkout__summaryHeader-31Ka5 {
	display: none;

	color: var(--text-base);
}

.checkout__flight-gL_or {
	display: none;
}

div.field_birthdate-ttArF {
	width: 53%;
}

.thanksBanner__wrapper-1lONH {
	border-radius: 20px;

	box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.06);
}

h1.thanksBanner__title-3wMXF {
	color: var(--text-base);
	font: var(--big--1-desktop-bold);
}

.closeButton-1p7S4 {
	width: 32px;
	height: 32px;

	border: none;

	color: var(--text-pale);

	background: var(--bg-white);

	transition: color 0.15s, background 0.15s;
}

.closeButton-1p7S4:hover {
	color: var(--text-pale);

	background: var(--bg-elements);
}

.closeButton-1p7S4 svg {
	width: 20px;
	height: 20px;
}

.serviceWithSegments__wrapper-dUtkd {
	max-width: 1156px;
}

button.counter__button-1teCE {
	color: var(--brand-1-0);

	background: var(--brand-1-5);
}

button.counter__button-1teCE:not(.counter__buttonDisabled-D-uWe):hover {
	color: var(--white);

	background: var(--brand-1-0);
}

button.counter__button-1teCE:not(.counter__buttonDisabled-D-uWe):active {
	color: var(--white);

	background: var(--brand-1--1);
}

button.counter__buttonDisabled-D-uWe {
	color: var(--line-dot-line);

	background: var(--bg-fill);

	pointer-events: none;
}

.notice-3-8a2 {
	border-radius: 20px;
}

.selectedFlight-Qs9KN .flightNumber-3X9SD {
	color: var(--brand-1-0);
}

.checkout__form-2caYa > form {
	display: flex;
	flex-direction: column;
}

.checkout__form-2caYa .checkout__contacts-3NXEx {
	order: 1;
}

.checkout__form-2caYa .toolbar__checkbox-3403a {
	order: 2;
}

.gdsTotalPrice-3n4MJ {
	color: var(--text-mid);
}

.price__money-1bagk {
	color: var(--text-base);
	font: var(--medium-1-mobile-bold);
}

@media screen and (max-width: 600px) {
	.checkout__container-1M_E3 {
		padding-bottom: 80px;
	}

	.thanksBanner__wrapper-1lONH {
		border-radius: 0;
	}

	.selectedFare__price-2mYw0,
	.selectedFare-3LAWP {
		color: var(--brand-1-0);
	}

	.checkout__summary-3I2Gl {
		margin-top: -33px;
	}

	.group__container-3jCI8 {
		grid-gap: 24px 28px;
		gap: 24px 28px;
	}

	.group__container-3jCI8:not(:last-child) {
		margin-bottom: 28px;
	}
}
