

div.input-26qiI:before {
	background: var(--brand-1-5);
}

.textField__root-2XueY.textField_filled-1Ud6Y {
	left: 4px;
}

div.input-26qiI.input_filled-3dBe9 .real_input-3XDKD {
	padding: var(--input-filled-pd);
}

div.input-26qiI.input_filled-3dBe9:before,
div.input-26qiI.input_standard-3him0:before {
	background: transparent;
}

.root-JoUik div.input-26qiI.input_standard-3him0:before {
	border-bottom-color: #d9d9d9;
	border-bottom-width: 1px;
}

div.input-26qiI.input_standard-3him0:after {
	z-index: 1;

	border-bottom-width: 1px;
}

input.real_input-3XDKD.disabled-31wvA {
	padding-left: 9px;
}

input.real_input-3XDKD:-ms-input-placeholder {
	color: var(--text-light);
}

input.real_input-3XDKD::placeholder {
	color: var(--text-light);
}

label.textField__root-2XueY {
	overflow: visible;
}

label.textField__root-2XueY.textField-2MHnn:not(.label_error-2opVC) {
	color: var(--brand-1-0);
}

label.textField__root-2XueY.label_error-2opVC {
	color: var(--text-light);
}

.input_filled-3dBe9.input_focused-24U_i input.real_input-3XDKD,
.input_filled-3dBe9.input_focused-24U_i div.real_input-3XDKD {
	border-color: var(--brand-1-0);
}

.input_focused-24U_i:not(.input_outline-28yGz) input.real_input-3XDKD {
	--input-background-active: var(--input-outline-bg-active);
}

label.textField__root-2XueY.label_error-2opVC + div.input-26qiI:before {
	background: var(--error-bg);
}

label.textField__root-2XueY.label_error-2opVC + div.input-26qiI input.real_input-3XDKD {
	border-bottom: 1px solid var(--error);

	background: transparent;
}
