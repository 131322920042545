.meal__dialogContent-iC_zW {
	height: auto;
	min-height: 100%;
	padding-bottom: 120px;
}

@media screen and (max-width: 600px) {
	.baggagePage-1pZP- {
		padding-bottom: 80px;
	}
}
