.info-2vl48 {
	display: none;
}

.airline-mMNeY {
	display: flex;
	flex-direction: row;
	flex-grow: 1;
}

.airline__name-3qSAw {
	font: var(--normal-desktop-accent);
	color: var(--brand-1-0);
}

.airline__number-1qyjQ {
	font: var(--normal-desktop-accent);
	color: var(--brand-1-0);
}

@media screen and (max-width: 600px) {
	.flightCard__aircraftName-MhR5L {
		margin-left: 0;
	}
}
