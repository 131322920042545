

.container-1Ba8a {
	margin-top: 0;
	grid-gap: 4px;
	gap: 4px;
}

.container-1Ba8a .label-2Y1Dj {
	width: 54.5px;
	height: 56px;
	padding: 16px;

	border: none;
	border-radius: 5px;
}
