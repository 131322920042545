

div.day__wrapper-3jQoW {
	padding-top: 0;
}

div.day-2pr2n {
	height: 95px;
}

div.day-2pr2n.active-RFan1 {
	height: 95px;

	border-color: var(--brand-1-0);
}

div.date-AvHNh,
div.day-2pr2n.active-RFan1 div.date-AvHNh {
	color: var(--text-mid);
	font-size: 16px;
	font-weight: 600;
}

div.date__info-37Tiy:before {
	position: absolute;
	top: 0;
	right: 30px;
	bottom: 0;
	left: 30px;
	z-index: -1;

	height: 1px;
	margin: auto;

	border-top: 1px dashed var(--line-separator);
	content: '';
}

div.date__info-37Tiy span {
	padding: 0 4px;

	background: #ffffff;
}

span.price-2ilMP,
div.day-2pr2n.active-RFan1 span.price-2ilMP {
	color: var(--text-base);
	font-size: 24px;
}

/* stylelint-disable-next-line block-no-empty */
div.arrow-37YqV {
}

div.arrow-37YqV:after {
	margin-top: 0;
}

.container-2JAMD > div > div {
	border-radius: 20px;
}
