

div.serviceCard-22B_u > div:first-child {
	height: 129px;

	border-radius: 0;
}

div.serviceCard-22B_u.serviceCard_baggage-21pqK > div:first-child {
	background: url('https://cdn.websky.aero/content/frontend/images/IO/CardExtra/baggage.jpg') no-repeat 50% 50% /
		cover;
}

div.serviceCard-22B_u.serviceCard_meal-1qs1l > div:first-child {
	background: url('https://cdn.websky.aero/content/frontend/images/IO/CardExtra/meal.jpg') no-repeat 50% 50% / cover;
}

div.serviceCard-22B_u.serviceCard_seats-1NcJo > div:first-child {
	background: url('https://cdn.websky.aero/content/frontend/images/IO/CardExtra/seats.jpg') no-repeat 50% 50% / cover;
}

div.serviceCard-22B_u.serviceCard_meal-1qs1l > div:first-child:before,
div.serviceCard-22B_u.serviceCard_seats-1NcJo > div:first-child:before,
div.serviceCard-22B_u.serviceCard_baggage-21pqK > div:first-child:before {
	content: none;
}

.name-XeeVc {
	color: var(--text-mid);
}

.services_exchange-1_sEK .header__text-1uUf3 {
	margin-top: 166px;

	color: var(--brand-1-0);
}

.services_exchange-1_sEK .content-29aUl {
	margin-top: 45px;
}

.passengerName-3JcEQ {
	font-weight: 700;
}

.price-3MNTA {
	font-weight: 400;
}

.totalRefund-1rRRp,
.price-3MNTA,
.passengerName-3JcEQ {
	color: var(--text-base);
}
