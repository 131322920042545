.initial__wrapper-39Rr9 {
	display: flex;
	flex-direction: row-reverse;
	max-width: 1100px;
}

.initial__form-2vFvz {
	flex: 1;
	padding: 28px 32px 32px;

	border-radius: 20px;

	box-shadow: none;
}

.initial__form-2vFvz:hover {
	box-shadow: none;
}

.checkin__servicePrice-3E0qk {
	padding-right: 0;

	border-right: none;

	color: var(--text-mid);
}

.serviceRecommendation-1Hq7J {
	border-radius: 0;
}

.payment__wrapper-2PiEa,
.payment__header-3YUAD {
	border-radius: 0;
}

.extrasBanner-2rrCf {
	display: none;
}

.insurance__header-32B7u {
	background: linear-gradient(180deg, var(--brand-1-2) 0%, var(--brand-1-0) 100%);
}

button.insurance__button-1pA9f {
	background: var(--brand-1-0);
}

button.insurance__button-1pA9f:hover {
	background: var(--brand-1-2);
}

button.insurance__button-1pA9f:active {
	background: var(--brand-1-3);
}

.resendTicket__wrapper-FOGBR,
.checkinCancelModal__content-1H4ql {
	padding-top: 54px;
}

.passengersContent__passengers-2sddx {
	border-radius: 20px;

	box-shadow: var(--card-box-shadow);
	overflow: hidden;
}

.passengersHeader__container-GIXhp {
	height: 55px;
}

.passengersHeader__title-22ZkL {
	font: var(--medium-1-desktop-bold);
}

.order__container-2cgsZ .order__stepBar-1buH0 {
	height: 45px;
	padding-top: 12px;
}

.selectedService__service-3tp_b {
	border-radius: 10px;
}

button.resendTicket__cancel-1UJA6,
button.resendTicket__cancel-1UJA6:hover {
	color: var(--white);

	background-color: var(--brand-3--0);
}

@media screen and (max-width: 600px) {
	.passengersHeader__container-GIXhp {
		display: none;
	}

	.passengersContent__passengers-2sddx {
		border-radius: 0;
		overflow: visible;
		overflow: initial;

		box-shadow: none;
	}

	.initial__wrapper-39Rr9 {
		flex-direction: column;
	}

	.passengers__content-2uai6 {
		padding-bottom: 100px;
	}

	.passengers__segments-RR8SL {
		padding: 12px;

		background: transparent;
	}

	.passengers__wrapper-3XgP_ {
		padding: 0 12px 12px;
	}

	button.resendTicket__submit-1hZTX,
	button.resendTicket__cancel-1UJA6 {
		flex: 50%;
	}
}
