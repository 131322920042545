.selectedPassenger__passengerInfo-pjhg4 {
	flex-direction: column;
	align-items: flex-start;
}

.selectedPassenger__passengerInfoItem-1rC5h {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 400;
	line-height: 1.3;
}

.selectedPassenger__passengerInfoItem-1rC5h:not(:last-child) {
	margin-right: 0;
	margin-bottom: 6px;
	padding-right: 0;

	border-right: none;
}

@media screen and (max-width: 600px) {
	.selectedPassenger__container-1ANg1 {
		box-shadow: var(--card-box-shadow);
	}

	.selectedPassenger__container-1ANg1:first-child {
		border-radius: 12px 12px 0 0;
	}

	.selectedPassenger__container-1ANg1:last-child {
		border-radius: 0 0 12px 12px;
	}
}
