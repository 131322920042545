

.segmentCommon__wrapper-r1eax {
	background: var(--white);
	box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.06);
}

.segmentCommon__wrapper-r1eax.segmentCommon__wrapperBusiness-V6tLf {
	background: var(--white);
}

.segmentCommon__header-1W_CK {
	flex-direction: row-reverse;
	padding: 20px 20px 8px;

	border-bottom: 1px dashed var(--line-dot-line);
}

.segmentCommon__cityWeather-j6OPr {
	display: none;
}

.segmentCommon__flightInfoItem-1ceI4:first-child {
	order: 1;
	margin-left: 40px;
}

.segmentCommon__flightInfoItem-1ceI4:last-child {
	margin-left: 0;
}

.segmentCommon__flightInfoTitle-1XWc2,
.segmentCommon__cityAirport-XCu4q {
	color: var(--text-light);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
	text-transform: uppercase;
}

.segmentCommon__flightInfoValue-PPguG,
.segmentCommon__cityInfo-Tkzjx {
	color: var(--text-base);
	font-size: 42px;
	font-weight: 300;
	text-transform: uppercase;
	line-height: 1.3;
}

.segmentCommon__flightInfoItem-1ceI4:last-child .segmentCommon__flightInfoValue-PPguG {
	font-weight: 700;
}

.segmentCommon__cityIata-3zSbw {
	font-weight: 700;
}

.segmentCommon__cityTime-1X-4r {
	font-weight: 300;
}

.segmentCommon__planeIcon-DEfZ2 {
	width: 30px;
	height: 30px;
	margin: 0 26px;

	color: var(--text-pale);

	transform: rotate(180deg) translateY(-10px);
}

.segmentCommon__planeIcon-DEfZ2 > svg {
	width: 100%;
	height: 100%;
}

.segmentCommon__airlineName-kBIyg {
	color: var(--text-base);
	font-size: 16px;
	font-weight: 500;
	line-height: 1.3;
}

.segmentCommon__footerItem-15G2N {
	display: flex;
	flex-direction: column;
}

.segmentCommon__footerItem-15G2N:not(:last-child) {
	margin-right: 28px;
	padding-right: 0;

	border-right: none;
}

.segmentCommon__footerItemTitle-oQZp_ {
	color: var(--text-light);
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
	text-transform: uppercase;
}

.segmentCommon__footerItemTitle-oQZp_:after {
	display: none;
}

.segmentCommon__footerItem-15G2N:last-child .segmentCommon__footerItemTitle-oQZp_ {
	text-align: right;
}

.segmentCommon__footerItemValue-2-aC8 {
	color: var(--text-base);
	font-size: 18px;
	font-weight: 700;
	line-height: 1.3;
}

.boardingPassDesk__wrapper-1FNcJ {
	border-radius: 20px;

	box-shadow: var(--card-box-shadow);
}
