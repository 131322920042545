div.selectedFlights-2-e0_ > div {
	max-width: 1100px;
	margin: 0 auto;
}

div:has(> div.fareConditions__paperRoot-1BFSN) {
	border-radius: 20px;
	overflow: hidden;
}

button.fareConditions__segmentTabsTab-3Hvfc {
	font: var(--medium--1-desktop-accent) !important;
}

.results-33xJJ {
	min-height: 100vh;

	background: var(--bg-fill);
}

.title__text-2D-8w,
.title__cities-1ePbL {
	font: var(--medium-1-desktop-bold);
	color: var(--text-base);
}

.title__cities-1ePbL > svg {
	display: none;
}

.title__cities-1ePbL span:last-child:before {
	content: ' \2014   ';

	margin-left: 6px;

	font: var(--medium-1-desktop-bold);
	color: var(--text-base);
}

.calendar-ZhIIR {
	grid-gap: 12px;
	gap: 12px;
}

.title__cities-1ePbL .calendar-ZhIIR:first-child {
	margin-top: 0;
}

.calendar__header-25iFB {
	height: 52px;
}

.calendar__content-2jOMO {
	padding: 0;
	min-height: 95px;
}

.dummyWeekCalendar__wrapper-5nawb {
	padding: 0;

	border-radius: 20px;
	overflow: hidden;
}

.dummyWeekCalendar__item-1Bjvp {
	display: flex;
	align-items: center;
	justify-content: center;
}

.legNumber-U_obr {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 36px;
	height: 36px;

	color: var(--bg-white);
	font: var(--medium--1-desktop-accent);

	background-color: var(--brand-1-0);
}

img.compareFares__image-GnMcK {
	display: none !important;
}

button.diagram-ZGL2V {
	color: var(--brand-1-0);
	font: var(--normal-desktop-accent);
}

button.diagram-ZGL2V svg path {
	fill: var(--brand-1-0);
}

div:has(div.compareFares-qlTNN) {
	border-radius: 20px;
}

div.selectedFlights-2-e0_ {
	max-width: 100%;
	margin: 0;
	padding-bottom: 28px;

	background: #ffffff;
}

div.selectedFlights-2-e0_:last-child {
	margin-bottom: 20px;
}

div.selectedFlights-2-e0_:first-child > div {
	margin-top: 20px;
}

h1.selectedFlights__title-Ggt7r {
	position: relative;

	display: block;
	height: auto;
	padding-top: 36px;
	padding-bottom: 20px;
	padding-left: calc(((100vw - 1100px) / 2) + 52px);

	color: var(--text-base);

	background: #ffffff;
}

h1.selectedFlights__title-Ggt7r:before {
	position: absolute;
	top: 15px;
	bottom: 0;
	left: calc((100vw - 1100px) / 2);

	width: 36px;
	height: 36px;
	margin: auto;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzYiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCAzNiAzNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjM2IiBoZWlnaHQ9IjM2IiByeD0iMTgiIGZpbGw9IiM2RkI5MTQiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNy41NTMzIDIyLjczMDlMMjcuODc0MSAxMi40MTAxTDI2LjEwNjMgMTAuNjQyNEwxNS43ODU1IDIwLjk2MzJMMTAuNzM1NCAxNS45MTMxTDguOTY3NjYgMTcuNjgwOUwxNC4wMTc3IDIyLjczMDlMMTQuMDE3NiAyMi43MzExTDE1Ljc4NTQgMjQuNDk4OEwxNS43ODU1IDI0LjQ5ODdMMTUuNzg2IDI0LjQ5OTJMMTcuNTUzNyAyMi43MzE0TDE3LjU1MzMgMjIuNzMwOVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=) center no-repeat;
	content: '';
}

.inner-1D6ql {
	padding: 0 0 40px 0;

	border-radius: 0;

	background: var(--bg-fill);
}

div.otherDates-1OEAk {
	position: relative;

	border: 1px solid var(--brand-1-3);
	border-radius: 0;

	color: var(--text-link);

	background: #ffffff;
}

div.otherDates-1OEAk svg {
	display: none;
}

div.otherDates-1OEAk div.text-Jpxqd {
	padding-right: 4px;

	color: var(--text-link);
	font-weight: 600;
	text-decoration: none;
}

div.otherDates-1OEAk:after {
	width: 20px;
	height: 20px;
	margin-right: 4px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuOTk5OTkgMy4zMzMzMUw4LjgyNDk5IDQuNTA4MzFMMTMuNDc1IDkuMTY2NjVIMy4zMzMzM1YxMC44MzMzSDEzLjQ3NUw4LjgyNDk5IDE1LjQ5MTZMOS45OTk5OSAxNi42NjY2TDE2LjY2NjcgOS45OTk5OEw5Ljk5OTk5IDMuMzMzMzFaIiBmaWxsPSIjMzdBQUUxIi8+Cjwvc3ZnPgo=) center no-repeat;
	content: '';
}

div.calendar_tabs-1Lhzl {
	flex-direction: row-reverse;
}

div.compareFares-qlTNN {
	padding: 32px;

	border-radius: 20px;
}

.compareFares__content-2TWcV {
	padding: 0 0 20px;
}

.compareFares__tableRow-1ulZu {
	border-bottom: 1px solid var(--line-separator);

	font: var(--small-desktop);
}

.compareFares__tableRow-1ulZu:nth-of-type(even) {
	background: var(--brand-1-5);
}

.compareFares__tableRow-1ulZu .compareFares__title-1p0oH {
	font: var(--small-desktop-bold);
}

.compareFares__cell-1xkMS {
	margin: 0 auto;
}

.compareFares__cellContent-1vOAg,
.compareFares__tableRow-1ulZu:nth-child(odd) .compareFares__cellContent-1vOAg {
	color: var(--text-base);
	font: var(--small-desktop);
	font-weight: 400;
}

.compareFares__fareHeadingItem-3Acth {
	color: var(--text-mid);
	font: var(--medium--1-desktop-accent);
	text-align: center;

	background: var(--brand-1-5);
}

.compareFares__fareHeadingItem-3Acth.compareFares__fareHeadingItemIsKey-2NNdn {
	color: var(--bg-white);

	background: var(--brand-1-0);
}

.compareFares__slider-1o2_M .slick-slider .slick-list {
	border-radius: 20px;
	overflow: hidden;
}

.compareFares__slider-1o2_M .slick-arrow {
	top: 26px;

	width: 38px;
	height: 38px;
}

.compareFares__slider-1o2_M .slick-arrow,
.compareFares__slider-1o2_M .slick-arrow:hover {
	background: var(--white);
}

.compareFares__slider-1o2_M .slick-arrow > svg path {
	fill: var(--text-base);
}

.compareFares__slider-1o2_M .slick-arrow.slick-prev {
	box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.06);
}

.compareFares__slider-1o2_M .slick-arrow.slick-next {
	box-shadow: -4px -4px 18px rgba(0, 0, 0, 0.06);
}

.compareFares__slider-1o2_M .slick-arrow.slick-disabled {
	color: var(--line-dot-line);

	box-shadow: none;
	opacity: 1;
}

.compareFares__slider-1o2_M .slick-arrow.slick-disabled > svg path {
	fill: var(--text-light);
}

.selectedFares__wrapper-Ekosm {
	padding: 32px;
}

div > button.sortings__flightType-3uu5U,
div > button.sortings__flightType-3uu5U:hover {
	color: var(--text-mid);

	background: var(--bg-white);
}

div > button.sortings__flightType_active-2e_Cp,
div > button.sortings__flightType_active-2e_Cp:hover {
	color: var(--bg-white);

	background: var(--brand-1-0);
}

div > button.shareButton-talXt {
	padding: 12px 44px 12px 66px;
}

.currentSorting-1NEzf {
	color: var(--text-mid);
}

@media (max-width: 1024px) {
	.inner-1D6ql {
		max-width: 100%;
		margin: 0;
		padding: 0 30px 100px;
	}

	h1.selectedFlights__title-Ggt7r {
		padding: 20px 20px 20px 72px;
		margin: 0;

		color: var(--text-mid);
	}

	h1.selectedFlights__title-Ggt7r:before {
		left: 20px;
		top: 0;
	}

	.selectedFlights__title-Ggt7r {
		color: var(--text-base);
		font: var(--big--1-mobile-bold);
	}

	.selectedFlights__title-Ggt7r:before {
		line-height: 1;
	}

	.selectedFlights__wrapper-1HZb3 div.selectedFlights-2-e0_ > div {
		max-width: 100%;
		margin: 0 auto;

		border-radius: 10px;
	}

	div.selectedFlightsselectedFlights-1KynS:first-child > div {
		margin-top: 0;
	}

	div.outer-3N0Z0,
	div.mobileSelectedFares-19DRO {
		max-width: 460px;
		margin: 0 auto;
	}
}

@media screen and (max-width: 600px) {
	.inner-1D6ql {
		padding: 0 0 90px 0;
	}

	div:has(div.compareFares-qlTNN) {
		border-radius: 0;
	}

	.compareFares__slider-1o2_M .slick-slider .slick-list {
		border-radius: 0;
	}

	div.compareFares-qlTNN {
		padding: 0;
	}

	h1.selectedFlights__title-Ggt7r {
		font-size: 20px;
	}

	div.selectedFlights-2-e0_ {
		padding: 0 0 12px 0;
	}

	div.flightList-1tH5l {
		padding: 20px 12px 12px 12px;
	}

	div.outer-3N0Z0 {
		padding: 12px;
	}

	.compareFares__groupTitle-1V2Id {
		padding-left: 8px;
		padding-right: 8px;
	}

	.selectedFlights__wrapper-1HZb3 {
		width: 100%;
		padding: 12px;
		margin: 0;
	}
}
