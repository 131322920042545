.meal-1Po0s {
	height: auto;
	grid-gap: 20px;
	gap: 20px;
}

.meal_fullSize-K35Wu {
	grid-gap: 32px;
	gap: 32px;
}

.meal-1Po0s:after {
	z-index: 2;
}

.meal-1Po0s,
.meal-1Po0s:after {
	border-radius: 20px;
}

.meal-1Po0s:hover:after {
	border: 2px solid var(--brand-1-0);
}

.meal-1Po0s:not(.meal_fullSize-K35Wu) {
	padding-bottom: 20px;
}

.header-3TG60 {
	position: relative;
}

.marker-vxixb {
	top: 20px;
	left: 20px;

	font: var(--medium--1-desktop-accent);

	background: var(--additional-meal);

	transform: none;
}

.title-2ZTXr {
	padding: 0 12px;
}

.meal_fullSize-K35Wu .title-2ZTXr {
	margin-top: 0;
}

.image-1QXQ1 {
	height: auto;
	padding-top: 57.47%;
}

.name-2_dmv {
	color: var(--text-mid);
}

.footer-2BmOz {
	margin-top: 0;
}

.meal_fullSize-K35Wu .footer-2BmOz {
	width: 100%;
	max-width: 28%;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	grid-gap: 20px;
	gap: 20px;

	padding: 20px 32px 20px 0;
}

.meal_fullSize-K35Wu .footer__icons-311ga {
	margin-top: 0;
}

.price-1O91t {
	padding: 8px 16px;

	color: var(--white);
	font: var(--medium-desktop-bold);

	background: var(--brand-1-0);
}

.count-1UMnd {
	font: var(--medium--1-desktop-accent);
	line-height: 32px;

	background: var(--success-icon);
}

div.popup-F_SoO.popup-F_SoO {
	border-radius: 20px;
}

button.details__button-3yVcE {
	border-radius: 60px;
}

button.details__buttonEmpty-3x_vE {
	transition: color 0.3s, opacity 0.3s;
}

.details__totalText-3Lq5D,
.details__total-1FpC0 {
	font: var(--medium-mobile-bold);
}

.list__item-iA1FV,
.list__itemSelected-8roTC {
	padding: 12px 14px;
}

.list__wrapper-3ueP2 {
	grid-gap: 12px;
	gap: 12px;
}

.list__item-iA1FV {
	font: var(--normal-desktop-accent);
	color: var(--text-mid);

	transition: color 0.15s, background 0.15s;
}

.list__itemSelected-8roTC {
	color: var(--white);

	opacity: 1;

	background: var(--brand-1-0);
}

.list__item-iA1FV,
.list__item-iA1FV:hover,
.list__itemSelected-8roTC {
	box-shadow: 4px 4px 18px 0 rgba(0, 0, 0, 0.06);
}

.item__icon-iikc4 {
	display: none;
}

.categories__wrapper-3SQan button.categories__categoryButton-3iow7 {
	border-radius: 60px;
}

@media screen and (max-width: 600px) {
	.meal-1Po0s,
	.meal-1Po0s:after {
		border-radius: 10px;
	}

	.meal-1Po0s:after,
	.meal-1Po0s:hover:after {
		border-width: 1px;
	}
}
