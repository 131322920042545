.serviceSummary_cart-3_SNh .wrapper-1Szqc {
	padding: 12px;

	border-bottom: none;
}

.serviceSummary_cart-3_SNh .header-3xNkS svg,
.serviceSummary_cart-3_SNh .header__title-3AE8w,
.serviceSummary_cart-3_SNh .header__price-6pI6O {
	color: var(--brand-1-0);
}

.serviceSummary_cart-3_SNh .content--0SGX {
	border-radius: 10px;
}

button.button_add-3limw.button_add-3limw,
button.button_add-3limw.button_add-3limw:hover {
	border: none;
}

@media screen and (max-width: 600px) {
	.option__wrapper-25ggp {
		max-width: 100%;
	}
}
