.wrapper-1wqYR {
	border-radius: 20px;

	box-shadow: 4px 4px 18px 0 #0000000f;
	overflow: hidden;
}

.title-of7k3 {
	margin-bottom: 10px;

	color: var(--text-base);
	font: var(--medium-1-desktop-bold);
}

.description-2z1iR {
	color: var(--text-light);
	font: var(--medium--1-desktop);
}

.icon-3mKbn circle:first-of-type {
	fill: #e4faed;
	stroke: var(--text-accent);
	stroke-width: 1px;
}

.icon-3mKbn circle:last-of-type {
	fill: transparent;
	stroke: var(--text-accent);
	stroke-width: 1px;
}

.icon-3mKbn path:first-of-type {
	fill: var(--text-accent);
}

.icon-3mKbn defs {
	display: none;
}

.switchBlock-2yBYD {
	min-width: 225px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 20px;

	border-left: var(--line-separator) 1px dashed;

	text-align: left;

	color: var(--brand-1-0);

	background: var(--bg-white);
}

.switchBlock__priceWrapper-5nx6C {
	margin-top: 4px;
}

.switch_active-2BJWQ:before {
	background: var(--bg-white);
}

.switch_active-2BJWQ:after {
	background: var(--brand-3-0);
}

@media (max-width: 1024px) {
	.title-of7k3 {
		color: var(--brand-1-0);
	}

	.wrapper-1wqYR {
		display: flex;
		flex-direction: column;
		max-width: 460px;

		border: 1px solid var(--brand-1-3);
		border-radius: 4px;

		background: var(--brand-1-5);
	}

	.description_block-2U7WV {
		display: flex;
		flex-direction: column;
	}

	div.switchBlock-2yBYD {
		border-top: 1px dashed var(--line-separator);
		border-radius: 0 0 4px 4px;

		color: var(--brand-1-0);

		background: #ffffff;
	}

	div.icon-3mKbn svg {
		width: 79px;
		height: 79px;
	}
}

@media screen and (max-width: 600px) {
	.title-of7k3 {
		font: var(--medium-1-mobile-bold);
	}

	.wrapper-1wqYR {
		margin: 20px 12px;

		border-radius: 20px;
	}

	.switchBlock-2yBYD {
		flex-direction: row-reverse;
		grid-gap: 6px;
		gap: 6px;
		align-items: center;
		justify-content: flex-end;

		font: var(--medium-mobile-accent);
	}

	.switchBlock__priceWrapper-5nx6C div {
		font: var(--medium-mobile-accent);
	}

	.switchBlock-2yBYD div:first-of-type {
		width: 100%;
	}

	.switchBlock__priceWrapper-5nx6C {
		margin-top: 0;
		padding: 0;
	}

	.switchBlock__priceWrapper-5nx6C:after {
		content: ',';

		font: var(--medium-mobile-accent);
	}

	.icon-3mKbn circle:first-of-type {
		fill: transparent;
		stroke: var(--brand-1-0);
		stroke-width: 1px;
	}

	.icon-3mKbn circle:last-of-type {
		fill: transparent;
		stroke: var(--brand-1-0);
		stroke-width: 1px;
	}

	.icon-3mKbn path:first-of-type {
		fill: var(--brand-1-0);
	}

	.icon-3mKbn defs {
		display: none;
	}
}
