

.mobileSegment__wrapper-3QJy8 {
	display: flex;
	flex-direction: column;

	border-radius: 12px;

	box-shadow: var(--card-box-shadow);
	background: var(--white);
}

.mobileSegment__info-1z5eq {
	margin-bottom: 20px;

	border-bottom: none;
}

.mobileSegment__arrivalInfo-194fv {
	display: none;
}
