div.flight-1-H3h,
div.flight-1-H3h.selected-3XzJH {
	border-radius: 20px;
}

div.flight-1-H3h {
	border-radius: 20px;

	box-shadow: 4px 4px 18px 0 #0000000f;
}

div.flight-1-H3h.selected-3XzJH {
	border: none;
	border-radius: 20px;

	box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.06);
}

.timeInRoute-3eyJG {
	top: 17px;

	padding: 6px;

	font: var(--medium--1-desktop-accent);

	background: var(--bg-white);
}

.route-1ud1s {
	transform: translateY(-11px);
}

div > button.flightPrice-3dQr2 {
	border-radius: 10px;

	color: var(--text-base);

	background: var(--bg-elements);
}

div > button.flightPrice-3dQr2:hover {
	color: var(--text-base);

	background: var(--bg-elements);
}

.flightPrice__selectFrom-3QtQ5 {
	display: none;
}

.flightPrice__seats-3F533 {
	display: flex;
	flex-direction: row;
	grid-gap: 4px;
	gap: 4px;
}

.flightPrice__seats-3F533:before {
	display: block;
	width: 16px;
	height: 16px;

	color: var(--error-icon);

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIgogICAgICAgICAgZD0iTTIgMTJDMiA2LjQ4IDYuNDggMiAxMiAyQzE3LjUyIDIgMjIgNi40OCAyMiAxMkMyMiAxNy41MiAxNy41MiAyMiAxMiAyMkM2LjQ4IDIyIDIgMTcuNTIgMiAxMlpNMTMgMTFWMTdIMTFWMTFIMTNaTTEyIDIwQzcuNTg5OTkgMjAgMy45OTk5OSAxNi40MSAzLjk5OTk5IDEyQzMuOTk5OTkgNy41OSA3LjU4OTk5IDQgMTIgNEMxNi40MSA0IDIwIDcuNTkgMjAgMTJDMjAgMTYuNDEgMTYuNDEgMjAgMTIgMjBaTTEzIDdWOUgxMVY3SDEzWiIKICAgICAgICAgIGZpbGw9IiNFMDIyMjIiLz4KPC9zdmc+Cg==) no-repeat center / cover;
	content: '';
}

div.flightNumber-1mirc {
	font-weight: 400;
	color: var(--text-light);

	transform: translateY(-11px);
}

div.flightInfo__date-11lpD {
	color: var(--text-base);
	font-weight: 500;
}

.flightPrice__fareFamily-2syPO {
	color: var(--text-light);
}

.segment__planeInfo-2aCYq {
	width: unset;
}

.amenities__item-zBxAq.unavailable-PcVMu {
	display: none;
}

.segment__aircraft-2GJPg {
	text-align: right;
}

.flightPrice__price-1UjQv.flightPrice__priceSelected-3BS9H .flightPrice__priceMoney-6wm0z {
	color: var(--text-base);
}

.segment__flightInfo-1EaYj {
	margin-top: 4px;
}

.segment__segment-e7Ff2.segment__oneStop-1yDRm:after {
	display: none;
}

@media screen and (max-width: 600px) {
	div.flightNumber-1mirc {
		color: var(--brand-1-0);
	}
}
