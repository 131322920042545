.addPassenger__wrapper-1ygM7 {
	background: var(--white);
	box-shadow: none;
}

.addPassenger__formWrapper-27FLI {
	padding: 0;
}

.addPassenger__formTitle-keF5D {
	font-size: 18px;
}

button.addPassenger__addButton-2CZn1 {
	padding: 0;
	margin: 0;

	font-size: 16px;
}

.addPassenger__addButtonIcon-2L1AJ {
	position: relative;

	flex-shrink: 0;
	width: 32px;
	height: 32px;
	margin-right: 8px;

	border-radius: 50%;

	background: var(--brand-1-0);
}

.addPassenger__addButtonIcon-2L1AJ:before,
.addPassenger__addButtonIcon-2L1AJ:after {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;

	width: 40%;
	height: 2px;

	background: var(--white);
	transform: translate(-50%, -50%);
}

.addPassenger__addButtonIcon-2L1AJ:after {
	transform: translate(-50%, -50%) rotate(90deg);
}

.addPassenger__addButtonIcon-2L1AJ > svg {
	display: none;
}

.addPassenger__form-2eoz9 .addPassenger__submitButton-hzD8Q {
	position: relative;

	flex-shrink: 0;
	width: 52px;
	min-width: auto;
	height: 52px;
	margin-top: 0;
	padding: 0;

	border-radius: 50%;

	color: transparent;

	background: var(--brand-1-0);
}

.addPassenger__form-2eoz9 .addPassenger__submitButton-hzD8Q:hover {
	color: var(--brand-1--1);

	background: var(--brand-1--1);
}

button.addPassenger__submitButton-hzD8Q:before,
button.addPassenger__submitButton-hzD8Q:after {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;

	width: 30%;
	height: 2px;

	background: var(--white);
	transform: translate(-50%, -50%);
}

button.addPassenger__submitButton-hzD8Q:after {
	transform: translate(-50%, -50%) rotate(90deg);
}

.addPassenger__passengerIcon-1BBrb {
	display: none;
}

div.addPassenger__formInput-wjJBN {
	transform: none;
}

@media screen and (max-width: 600px) {
	.addPassenger__wrapper-1ygM7 {
		border-radius: 0 0 12px 12px;
	}

	button.addPassenger__addButton-2CZn1 {
		font-size: 14px;
	}

	button.addPassenger__conditionButton-7aArS > span {
		order: 1;

		margin-left: 8px;
		margin-right: -0;
	}
}
