button.fullscreenDialog__closeIcon-3sy5c {
	color: var(--text-pale);

	background: var(--bg-fill);
}

button.fullscreenDialog__closeIcon-3sy5c:after,
button.fullscreenDialog__closeIcon-3sy5c:before {
	width: 40%;
	height: 1px;
}

button.fullscreenDialog__closeIcon-3sy5c:hover {
	background: var(--brand-1-5);
}

@media screen and (max-width: 600px) {
	button.fullscreenDialog__closeIcon-3sy5c {
		margin-left: 0;
		flex-shrink: 0;
	}
}
