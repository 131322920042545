.location__airport-1toc_ {
	flex: 1;
}

.flightInfoSegment__isMobile-1YlZ6 .flightInfoSegment__flight-rgFYU {
	flex: 1;
}

.flightInfoSegment__isMobile-1YlZ6 .flightInfoSegment__locations-n2PZs {
	flex: 1;
}

.modal__title-2RstL {
	font: var(--big--1-desktop-bold);
}

.modalContent__compareFaresLink-2EMKt {
	font-weight: 400;
}

div.modalContent__fareGroup__slide-3UWiX {
	padding: 20px;

	border-radius: 20px;
}

.date__container-3X3K1 {
	align-items: center;

	background: red;
}

button.modalContent__fareGroup__button-37giu {
	padding: 12px 16px;

	border: none;

	font: var(--medium--1-desktop-accent);
	color: var(--text-mid);

	background: var(--bg-white);

	box-shadow: 4px 4px 18px 0 #0000000f;
}

button.modalContent__fareGroup__button_active-35wOD {
	color: var(--bg-white);

	background: var(--brand-1-0);
}

button.modalContent__fareGroup__button-37giu:disabled {
	color: var(--text-pale);
}

.flightInfoSegment__card-2cP6c {
	border-radius: 20px;
	overflow: hidden;
}

.flightInfoSegment__isMobile-1YlZ6 .flightInfoSegment__card-2cP6c {
	border-radius: 20px;
	overflow: hidden;

	box-shadow: 4px 4px 18px 0 #0000000f;
}

button.selectedFareGroup_button-1nWji {
	margin-top: 12px;

	border-radius: 30px;
}

@media screen and (max-width: 600px) {
	.modalContent__content-jO2fb {
		padding: 0;
	}

	.flightInfoSegment__flight-rgFYU {
		flex: 1;
	}

	.flightInfoSegment__locations-n2PZs {
		flex: 1;
	}

	.modalContent__contentWrapper-CXNn- {
		margin: 0;
		padding: 0 0 40px 0;
	}
}
