button.button-3yEwB,
button.createRequest_submit-3SwEZ {
	background: var(--brand-3-0);
}

button.button-3yEwB:hover,
button.createRequest_submit-3SwEZ:hover {
	background: var(--brand-3-1);
}

.buttons-1hEMK button.button-3yEwB,
.buttons-1hEMK button.button_back-PWX5Z {
	height: 48px;
	min-width: 160px;

	font: var(--medium-1-mobile-bold);
}

.controls-2wopw button.createRequest_submit-3SwEZ {
	font: var(--medium--1-desktop-bold);
}

.header__text-1xucs {
	font: var(--big-desktop-bold);
	color: var(--text-base);
}

.total-2EI71 {
	width: 100%;
	align-items: center;
	justify-content: space-between;

	font: var(--medium-1-mobile-bold);
}

.total__money-2I23Y {
	font: var(--big-desktop-bold);
}

.total-2EI71,
.header__name-uxsdn {
	color: var(--text-base);
}

span.labelInner-1G8rv {
	font-family: var(--font), Helvetica, Arial, sans-serif;
}

.chip-2ttu2 {
	font: var(--medium--1-desktop-accent);

	background: var(--brand-1-5);
}

.chip__close-26hak {
	color: var(--text-base);
}

button.calendar-9dL5H {
	padding: 0 12px;
}

.leg-20isX {
	background: var(--brand-1-0);
}

.calendar__closeIcon-3i92F {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
}

.selectedFlights__price-2vj2z {
	align-items: center;

	border-radius: 0 20px 20px 0;

	color: var(--text-base);

	background: var(--white);
}

.selectedFlights__price-2vj2z > span {
	font: var(--medium-1-desktop-bold);
}

.selectedFlights__flights-Oaj8U > div:not(:last-child) {
	margin-bottom: 1px;

	border-bottom: none;
}

.selectedFlights__flights-Oaj8U div.flight-1D5QY {
	border-radius: 20px 0 0 20px;
}

label.label-1yeD4 {
	border-radius: 10px;

	background: var(--bg-fill);
}

label.label-1yeD4.label_selected-3uLZX {
	background: var(--brand-1-4);
}

.charge-1TAgA {
	padding: 20px;

	color: var(--text-mid);
}

.charge-1TAgA:not(:last-of-type) {
	margin-bottom: 0;

	border-radius: 20px 20px 0 0;
}

.charge-1TAgA:last-of-type {
	position: relative;

	margin-bottom: 12px;

	border-radius: 0 0 20px 20px;
}

.charge-1TAgA:last-of-type:before {
	position: absolute;
	top: -1px;
	right: 0;
	left: 0;

	height: 1px;
	margin: 0 20px;

	background: var(--line-separator);
	content: '';
}

.charge-1TAgA > span {
	font: var(--medium--1-desktop);
}

.closeIcon-3Nxq7 {
	width: 32px;
	height: 32px;

	color: var(--text-pale);

	background: var(--bg-fill);
}

.choice_container-2ZI8i {
	border-radius: 20px;
}

.sort_title-2hjb5 {
	height: 40px;

	line-height: 40px;
	color: var(--text-mid);

	box-shadow: 4px 4px 18px 0 #0000000f;
}

.header-10733 {
	margin: 28px 0 19px;

	font: var(--medium--1-desktop-accent);
}

.change-28Iys {
	font: var(--normal-desktop-accent);
}

.createRequest_container-15gsd {
	padding: 20px 20px 40px;
}

.flight__container-3r_MG {
	margin-bottom: 1px;

	border-radius: 20px;

	background: none;
}

.flight__container-3r_MG:first-child {
	margin-top: 12px;
}

.requestForm_order-1LrFF {
	display: flex;
	grid-gap: 12px;
	gap: 12px;
	margin-bottom: 12px;

	border-bottom: none;
}

.requestForm_header-1WVqv {
	color: var(--text-base);
}

.close-20Jrc {
	grid-gap: 0;
	gap: 0;
	margin-right: -4px;
}

.requestControls_container-2LqLp button.requestControls_button-1UOrW {
	font: var(--medium--1-mobile-bold);

	background: var(--brand-3-0);
}

.requestControls_container-2LqLp button.requestControls_button-1UOrW:hover {
	background: var(--brand-3--1);
}

@media screen and (max-width: 600px) {
	.header-10733 {
		font: var(--medium--1-desktop-accent);
	}

	.header__inner-3dSwU {
		flex-direction: unset;
	}

	.inner_header-1k-qU {
		background: var(--white);
	}
}
