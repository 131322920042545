div.stepbar-30GMX {
	height: 45px;
	padding-top: 12px;

	background: var(--brand-1-0);
}

div.inner-1UMvf {
	align-items: flex-end;
	grid-gap: 20px;
	gap: 20px;
}

div.step-2WkVh {
	padding: 0 20px;
	flex-grow: 0;

	border-left: unset;

	font: var(--small-desktop-caps-bold);
}

div.step-2WkVh.active-2oY8h {
	border-radius: 4px 4px 0 0;

	color: var(--brand-1-0);

	background: #ffffff;
}

div.step-2WkVh.disabled-2q3DA {
	color: var(--brand-1-3);
}

.arrow-V1BV1 {
	display: none;
}

.id-5rWAa {
	display: none;
}

.cart-1n1ua {
	border-radius: 6px 6px 0 0;

	color: var(--text-link);
	font-size: 16px;
	font-weight: 600;

	background: var(--brand-1-5);
}

@media (max-width: 1024px) {
	div.step-2WkVh,
	.cart-1n1ua {
		padding: 0 14px;
	}
}

@media (max-width: 768px) {
	div.step-2WkVh,
	.cart-1n1ua {
		padding: 0 10px;
	}
}
