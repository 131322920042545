.contacts-2mEsT {
	border-radius: 20px;
}

.contacts-2mEsT,
.contacts-2mEsT:hover {
	box-shadow: none;
}

.description__text-3YgSG {
	font: var(--medium--1-desktop);
}

.footer_login-2w-IG {
	border-bottom-right-radius: 20px;
	border-bottom-left-radius: 20px;

	background: var(--bg-white);
}

.footer__inner-3rh-7 {
	flex: 1;
}

.footer__inner-3rh-7 > button {
	display: inline-block;
	margin-right: 16px;
	margin-left: auto;

	line-height: 1.2;

	text-overflow: ellipsis;
	overflow: hidden;
}

@media screen and (max-width: 600px) {
	.inner-2odG_ > button {
		margin-left: 0;
		margin-right: 0;
	}

	.inner-2odG_ {
		max-width: 80%;
		justify-content: flex-end;
	}
}
