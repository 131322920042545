div.modal-159TY {
	border-radius: 20px;
}

button.close-_C9dh {
	top: 24px;
	right: 32px;
}

@media screen and (max-width: 600px) {
	button.close-_C9dh {
		top: 12px;
		right: 12px;
	}
}
