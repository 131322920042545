.searchForm-QESAH {
	background: var(--white);
}

/* stylelint-disable block-no-empty */
.row-2pnj6 {
}

/* Button */
.startSearch-7XfGB {
	flex-basis: auto;
}

.startSearch-7XfGB button {
	width: inherit;
	height: calc(var(--input-height) + 4px);

	background-color: var(--brand-3-0);
}

.startSearch_multiCity-fbHy0 button {
	width: 100%;
}

.startSearch-7XfGB:hover button {
	background: var(--brand-3--1);
}

.startSearch-7XfGB:active button {
	background: var(--brand-3--2);
}

.startSearch-7XfGB button.button_flat-3_-Ho.button_large-GNe-3 {
	height: calc(var(--input-height) + 2px);
	padding: 0;

	border-radius: 0;
}

.startSearch-7XfGB.startSearch_iconMode-33rzH button {
	border-radius: 0 20px 20px 0;
}

.startSearch_multiCity-fbHy0 {
	margin-right: -2px;
	width: 33.33%;
}

button.button-1_A1A {
	min-width: 64px;
}

/* Passenger */
button.increase-R7KN1,
button.decrease-GwwE1 {
	--brand-1-0: #0176fe;
	--brand-1-5: #e5f1ff;
	color: var(--brand-1-0);

	background: var(--brand-1-5);
}

button.increase-R7KN1 svg,
button.decrease-GwwE1 svg {
	fill: var(--brand-1-0);
}

button.increase-R7KN1:hover,
button.decrease-GwwE1:hover,
button.increase-R7KN1:active,
button.decrease-GwwE1:active {
	color: #ffffff;

	background: var(--brand-1-0);
}

button.increase-R7KN1:hover svg,
button.decrease-GwwE1:hover svg,
button.increase-R7KN1:active svg,
button.decrease-GwwE1:active svg {
	fill: #ffffff;
}

button.increase-R7KN1:disabled,
button.decrease-GwwE1:disabled {
	color: var(--line-dot-line);

	background-color: var(--gray-bg);
}

.counter-2Zmaf:not(.counter_disabled-3nYx6) {
	color: var(--text-base);
}

/* Inputs */
.input-33j6e,
button.input-33j6e,
.dates-2MeLb,
.selector-_BUhR {
	border-radius: 0;
}

@media (min-width: 1024px) {
	.input-33j6e,
	button.input-33j6e,
	.dates-2MeLb,
	.selector-_BUhR {
		height: 55px;
	}
}

.cell_location-21E1n .input-33j6e {
	border-bottom: 3px solid transparent;
}

.cell_location-21E1n .input-33j6e:focus {
	border-bottom-color: var(--brand-1-0);
}

.dayOfWeekName_withPrices-3gtjx span,
.dayOfWeekName-3b83b:nth-child(6n) span,
.dayOfWeekName-3b83b:nth-child(7n) span,
.dayOfWeekName_withPrices-3gtjx:nth-child(6n) span,
.dayOfWeekName_withPrices-3gtjx:nth-child(7n) span {
	font-weight: 500;
}

.dates-2MeLb > div,
.selector-_BUhR span:first-of-type {
	color: var(--text-base);
}

.selector-_BUhR span:last-of-type,
.dates_focused-2ooe8 .returnDelete-c-gl5 {
	color: var(--text-pale);
}

div.day_startHover_withPrice-1UHyN,
div.day_endHover_withPrice-2aQ_K {
	border: 1px solid var(--brand-1-0);

	color: #ffffff;

	background: var(--brand-1-0);
}

.day_withPrice-AaGyT.day_selected-2HTiP {
	padding: 8px;

	border-radius: 0;

	color: var(--white);

	background: var(--brand-1-0);
}

.dayOfWeekName_withPrices-3gtjx {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 38px;
	height: 36px;

	font: var(--medium--1-desktop-accent);
}

.day__price-1rG7t {
	margin-top: 5px;

	color: var(--text-light);
}

.day_withPrice-AaGyT.day_inPeriod-28STR,
.day_withPrice-AaGyT.day_weekend-206NF.day_inPeriod-28STR {
	color: var(--text-base);

	background: var(--brand-1-5);
}

.day_notAvailable-2qwhb {
	color: var(--text-pale);

	opacity: 1;
}

.day_today--eiUY {
	border: 1px solid var(--line-dot-line);
}

.month-_n9Xx {
	padding-right: 45px;
}

.content-1xNme {
	min-width: auto;
}

.nav_prev-3HuWZ {
	z-index: 2;
}

.dates__to-1lDak:before {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAxOCAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNSAyLjVIMTZDMTcuMSAyLjUgMTggMy40IDE4IDQuNVYxOC41QzE4IDE5LjYgMTcuMSAyMC41IDE2IDIwLjVIMkMwLjg5IDIwLjUgMCAxOS42IDAgMTguNUwwLjAxIDQuNUMwLjAxIDMuNCAwLjg5IDIuNSAyIDIuNUgzVjAuNUg1VjIuNUgxM1YwLjVIMTVWMi41Wk0yIDE4LjVIMTZWNy41SDJWMTguNVoiIGZpbGw9IiNCM0IzQjMiLz4KPC9zdmc+Cg==) center no-repeat;
}

.dates_focused-2ooe8 .dates__to-1lDak:after {
	display: none;
}

.dates_focused-2ooe8 .dates__to-1lDak:before {
	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAxOCAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNSAyLjVIMTZDMTcuMSAyLjUgMTggMy40IDE4IDQuNVYxOC41QzE4IDE5LjYgMTcuMSAyMC41IDE2IDIwLjVIMkMwLjg5IDIwLjUgMCAxOS42IDAgMTguNUwwLjAxIDQuNUMwLjAxIDMuNCAwLjg5IDIuNSAyIDIuNUgzVjAuNUg1VjIuNUgxM1YwLjVIMTVWMi41Wk0yIDE4LjVIMTZWNy41SDJWMTguNVoiIGZpbGw9IiNCM0IzQjMiLz4KPC9zdmc+Cg==) center no-repeat;
	filter: brightness(0.85);
}

.dates__back-29KjQ:before {
	border-left: 1px solid var(--gray-line);

	opacity: 1;

	opacity: initial;
}

.datesPlaceholder-2rwk_ {
	font: var(--medium--1-desktop-accent);
}

div.valueWrapper-2X6WU .value-2X5hS {
	color: var(--text-base);
}

div.valueWrapper-2X6WU .code-2cJol {
	color: var(--text-light);
}

div.valueWrapper-2X6WU .value-2X5hS,
div.valueWrapper-2X6WU .code-2cJol {
	font: var(--normal-desktop-accent);
}

.switcher-2o4os {
	right: 12px;

	width: 24px;
	height: 24px;

	color: var(--text-pale);

	opacity: 1;

	opacity: initial;
	background: var(--gray-bg);
}

.switcher_highlighted-3yVq1 {
	color: var(--brand-1-0);
}

.switcher-2o4os:hover {
	color: var(--brand-1-0);

	background: var(--brand-1-5);
}

.switcher-2o4os:active {
	color: #ffffff;

	background: var(--brand-1-0);
}

.passengers-2fsUu {
	position: relative;

	max-width: 158px;
	margin-right: 0;
	margin-left: 2px;

	border: 1px solid var(--line-separator);
	border-left: none;
}

.selector-_BUhR {
	padding-top: 3px;

	border-bottom: 3px solid transparent;

	font: var(--normal-desktop-accent);
}

.selector_focused-2y1U6 {
	border-bottom-color: var(--brand-1-0);
}

.passengers_isMultiCity-2aUwr {
	flex-basis: unset;
	width: 33.33%;
	max-width: none;
	margin-left: 0;

	border-left: 1px solid var(--line-separator);
	border-radius: 4px;
}

.dates__to_focused-3Tcjz,
.dates__back_focused-2dWyy {
	border-bottom: 3px solid var(--brand-1-0);
}

.dates__back_focused-2dWyy {
	margin-right: -1px;
	margin-left: 0;
}

.autocomplete-OPbNx,
.dates-2MeLb {
	position: relative;
}

/* Segment */
.segments-3RUNc {
	margin-right: -2px;
}

.segment_wrapper-3Q3bt {
	border-right: none;
}

.cell-m3MRJ {
	border: 1px solid var(--line-separator);
}

.cell_date-3iQOe {
	flex-basis: 245px;
}

.withMultiCity-nFaLE .cell_date-3iQOe {
	border-radius: 0 4px 4px 0;
}

.withMultiCity-nFaLE .cell-m3MRJ,
.withMultiCity-nFaLE .cell_date-3iQOe {
	flex: 1;
}

.cell-m3MRJ:first-child > div {
	border-radius: 20px 0 0 20px;
}

div.day_endHover_withPrice-2aQ_K:not(.day_selected-2HTiP) > div {
	color: var(--white);
}

.withMultiCity-nFaLE .cell-m3MRJ:first-child > div {
	border-radius: 4px 0 0 4px;
}

.cell-m3MRJ:not(:last-of-type) {
	margin-right: 0;

	border-right: none;
}

.cell-m3MRJ:nth-of-type(2) div.valueWrapper-2X6WU {
	display: flex;
	justify-content: space-between;
	padding-right: 16px;
}

.controls-2hn_S {
	flex-basis: 224px;

	border-left: none;
}

.controls_multiCity-1adR7 {
	flex-basis: 100%;
	margin-top: 16px;
}

.control-3lhko {
	margin-top: 7px;

	color: var(--brand-1-0);

	background: var(--brand-1-5);
}

.control-3lhko:hover {
	color: var(--white);

	background: var(--brand-1-0);
}

/* Footer */
.tabs-3nQ0x {
	display: none !important;
}

.footer-32ivs {
	justify-content: center;
	padding-bottom: 20px;

	border-top: none;
}

.footer__buttons-2SHQo {
	margin-right: 0;
}

button.footer__done-31mPy {
	border-radius: 50px;

	background: var(--brand-3-0);
}

button.footer__done-31mPy:hover {
	background: var(--brand-3--1);
}

button.footer__done-31mPy:active {
	background: var(--brand-3--2);
}

.footer__buttons-2SHQo:only-child {
	justify-content: center;
}

.datepicker__disclaimer-2_QVb {
	display: none;
}

/* LocationAutocomplete */
.group__label-Ik0rj {
	color: var(--brand-1-0);
	font-weight: 700;
	white-space: nowrap;
}

.option__label-1ra3S {
	color: var(--text-base);
}

.dow-20q0j {
	display: none;
}

button.footer__clear-6bXN2 {
	display: none;
}

.monthHeader-AeFlO {
	margin-bottom: 12px;
}

.monthHeader__monthHeaderSelectMonth-3_v5B {
	color: var(--text-base);
}

.monthHeader__monthHeaderSelectYear-3dMI6 {
	color: var(--text-pale);
}

.monthHeader__monthHeaderSelectMonth-3_v5B,
.monthHeader__monthHeaderSelectYear-3dMI6 {
	font: var(--medium-desktop-bold);
}

.location__input-3DXlt:-ms-input-placeholder {
	color: var(--text-light);
}

.location__input-3DXlt::placeholder {
	color: var(--text-light);
}

.switchWrapper-y_f86 {
	margin-left: 0;
}

.switchWrapper-y_f86:after {
	right: 0;

	background-color: var(--line-separator);
}

.switchWrapper-y_f86:before {
	right: 16px;

	opacity: 1;
}

.switchWrapper-y_f86.switchActive-xjx7r:after {
	background-color: var(--brand-3-0);
}

.switchWrapper-y_f86.switchActive-xjx7r:before {
	right: 3px;
}

.switchWrapper-y_f86:after,
.switchWrapper-y_f86:before,
.switchWrapper-y_f86.switchActive-xjx7r:before {
	left: auto;
}

.cell_location-21E1n:not(.cell_location_arrival-1Wa1Q),
.cell_location-21E1n:not(.cell_location_arrival-1Wa1Q) .input-33j6e,
.cell_location-21E1n:not(.cell_location_arrival-1Wa1Q) .autocomplete-OPbNx,
.cell_location-21E1n:not(.cell_location_arrival-1Wa1Q) .location__value-16Vjv {
	border-radius: 20px 0 0 20px;
}

.withMultiCity-nFaLE .cell_location-21E1n:not(.cell_location_arrival-1Wa1Q),
.withMultiCity-nFaLE .cell_location-21E1n:not(.cell_location_arrival-1Wa1Q) .input-33j6e,
.withMultiCity-nFaLE .cell_location-21E1n:not(.cell_location_arrival-1Wa1Q) .autocomplete-OPbNx,
.withMultiCity-nFaLE .cell_location-21E1n:not(.cell_location_arrival-1Wa1Q) .location__value-16Vjv {
	border-radius: 4px 0 0 4px;
}

.switchBox-1t7n6 {
	padding-left: 0;
	padding-right: 45px;
}

.multiCity__caption-2b6JX,
.switchBox-1t7n6 {
	color: var(--text-link);
	font: var(--normal-desktop-accent);

	opacity: 1;
}

/* SearchFormDatePicker */
.day_withPrice-AaGyT {
	width: 37px;
	height: 47px;
	margin: 0.5px;
	padding: 8px;

	font: var(--medium--1-desktop-accent);
}

.highlightedDate_withPrice-2aN_t {
	background: var(--brand-1-5);
}

.highlightedDate_withPrice-2aN_t .day_withPrice-AaGyT {
	color: var(--brand-1-0);
}

.day_withPrice-AaGyT.day_selected-2HTiP.day_inPeriod-28STR > div {
	color: var(--text-base);
}

.day_withPrice-AaGyT:not(.day_endHover_withPrice-2aQ_K):not(.day_startHover_withprice-c5KAW):not(.day_selected-2HTiP).day_notAvailable-2qwhb:hover {
	border: 1px solid var(--line-dot-line);
}

.day_withPrice-AaGyT:not(.day_endHover_withPrice-2aQ_K):not(.day_startHover_withprice-c5KAW):not(.day_selected-2HTiP):hover {
	border: 1px solid var(--brand-1-0);
}

.quickSearchForm__wrapper-u9nC8 {
	background: transparent;
}

@media (max-width: 1024px) {
	.row-2pnj6 {
		padding: 0 20px;
	}

	.cell_date-3iQOe {
		flex-basis: 50.1%;
		height: 54px;
		margin-top: 0;

		border-top: none;
	}

	button.footer__done-31mPy {
		width: 100%;
	}

	.withMultiCity-nFaLE .cell_date-3iQOe {
		border-top: 1px solid var(--line-separator);
		border-left: none;
	}

	.startSearch-7XfGB {
		position: absolute;
		left: calc(-100% - 2px);
		bottom: calc(-100% + 1px);

		margin: 0 20px 0 20px;
		width: calc(200% - 39px);

		flex: 0;
	}

	.datesPlaceholder-2rwk_ {
		font: var(--normal-desktop-accent);
	}

	.passengers-2fsUu {
		max-width: calc(100% + 1px);
		margin-left: -1px;

		border-top: none;
		border-bottom: none;
	}

	.controls-2hn_S {
		top: 54px;
		right: -2px;

		width: 50%;
		padding-right: 21px;
		margin-bottom: 0;
	}

	.controls_multiCity-1adR7 {
		top: auto;

		width: 100%;
		padding: 0 21px 0 20px;
	}

	.cell-m3MRJ:nth-child(2) {
		border-right: 1px solid var(--line-separator);
	}

	.cell-m3MRJ:nth-child(2) .input-33j6e,
	.cell-m3MRJ:nth-child(2) .location-rcicl {
		border-radius: 0;
	}

	.startSearch_multiCity-fbHy0 {
		left: 0;

		width: calc(100% - 40px);
	}

	.startSearch_multiCity-fbHy0 button {
		border-radius: 0 0 4px 4px;
	}

	.startSearch-7XfGB.startSearch_iconMode-33rzH button {
		width: 100%;

		border-radius: 0 0 8px 8px;
	}

	.dialog__dateBack-1TRIz:before {
		content: '\2014';

		padding: 0 8px;

		color: var(--text-pale);
	}

	.dialog__dateBack-1TRIz span {
		color: var(--brand-3-0);
	}

	.dialog__close-3xwKl {
		width: 20px;
		height: 20px;

		border-radius: 50%;

		background-color: var(--bg-elements);
		background-size: 70%;
	}

	.passengers_isMultiCity-2aUwr {
		width: 100%;

		border: none;
	}

	.selector-_BUhR {
		height: 53px;
	}

	.passengers_isMultiCity-2aUwr .selector-_BUhR {
		border-radius: 0;
		border: 1px solid var(--line-separator);
	}

	.cell_location-21E1n:not(.cell_location_arrival-1Wa1Q),
	.cell_location-21E1n:not(.cell_location_arrival-1Wa1Q) .input-33j6e,
	.cell_location-21E1n:not(.cell_location_arrival-1Wa1Q) .autocomplete-OPbNx,
	.cell_location-21E1n:not(.cell_location_arrival-1Wa1Q) .location__value-16Vjv {
		border-radius: 0;
	}

	.bottom-1VAmP {
		margin-top: 72px;
	}

	.bottom__controls-3ydTV {
		margin-bottom: 0;
	}

	.switchBox-1t7n6 {
		padding-right: 25px;
	}

	.month-_n9Xx {
		padding-right: 0;
	}

	.dialog__bar-2LQEu {
		background: var(--white);
		box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.03);
	}

	.dialog__dates-3-bYF {
		font: var(--medium-mobile-bold);
	}

	.dialog__activeDate-tXsmt {
		color: var(--text-base);
	}

	.dates__to_focused-3Tcjz,
	.dates__back_focused-2dWyy {
		margin-bottom: -1px;
		margin-left: -1px;
	}
}

@media screen and (max-width: 600px) {
	.beforeAdornment__wrapper-ApysY {
		margin: unset;
	}

	.searchForm-QESAH {
		height: 100vh;
		padding: 0;

		background: var(--brand-1-5);
	}

	.bottom-1VAmP {
		margin-top: 0;
	}

	.row-2pnj6:not(.bottom-1VAmP) {
		padding: 31px 20px;
	}

	.cell-m3MRJ,
	.controls-2hn_S {
		background: var(--white);
	}

	.cell-m3MRJ:not(:last-of-type) {
		margin-bottom: -1px;
	}

	.cell-m3MRJ:nth-of-type(2) div.valueWrapper-2X6WU {
		justify-content: center;
	}

	.cell_date-3iQOe {
		border-top: 1px solid var(--line-separator);
		border-bottom: 2px solid var(--line-separator);
	}

	.cell_location_arrival-1Wa1Q {
		border-right: 1px solid var(--gray-line) !important;
	}

	.withMultiCity-nFaLE {
		margin-bottom: -1px;
		width: calc(100% + 1px);
	}

	.actions_alternative-2IOcY {
		margin-right: -1px;
	}

	button.footer__done-31mPy {
		border-radius: 50px;
	}

	.withMultiCity-nFaLE .cell-m3MRJ {
		border-bottom: 1px solid var(--line-separator);
	}

	.withMultiCity-nFaLE .cell-m3MRJ:not(:last-of-type) {
		border-right: 1px solid var(--line-separator);
	}

	.withMultiCity-nFaLE .cell_date-3iQOe {
		border-bottom: none;
	}

	.withMultiCity-nFaLE .cell_location-21E1n:not(.cell_location_arrival-1Wa1Q),
	.withMultiCity-nFaLE .cell_location-21E1n:not(.cell_location_arrival-1Wa1Q) .input-33j6e,
	.withMultiCity-nFaLE .cell_location-21E1n:not(.cell_location_arrival-1Wa1Q) .autocomplete-OPbNx,
	.withMultiCity-nFaLE .cell_location-21E1n:not(.cell_location_arrival-1Wa1Q) .location__value-16Vjv,
	.withMultiCity-nFaLE .cell_date-3iQOe {
		border-radius: 0;
	}

	.segment_alternative-DbQqg .withMultiCity-nFaLE .cell_location-21E1n {
		flex: 1;
	}

	.segment_alternative-DbQqg .withMultiCity-nFaLE .cell_location-21E1n:nth-child(2) {
		border-left: none;
	}

	.segment_alternative-DbQqg .withMultiCity-nFaLE .cell_location-21E1n .input-33j6e {
		height: 50px;

		line-height: 50px;
	}

	div.valueWrapper-2X6WU .value-2X5hS {
		margin-top: 0;

		color: var(--text-mid);
		font: var(--small-mobile);
	}

	div.valueWrapper-2X6WU .code-2cJol {
		color: var(--text-base);
		font: var(--big--1-mobile-bold);
	}

	.segment_wrapper-3Q3bt:not(.withMultiCity-nFaLE) .location__input_icon-1-Fuj,
	.segment_wrapper-3Q3bt:not(.withMultiCity-nFaLE) .dates__to-1lDak .dates__icon-ALoeI {
		display: none;
	}

	.autocomplete_alternative-Yak0j .location__input-3DXlt {
		font: var(--normal-desktop-accent);
	}

	.controls-2hn_S {
		display: block;
		width: calc(100% + 2px);
		padding-right: 0;
	}

	.switchBox-1t7n6 {
		font-size: 16px;
	}

	.startSearch-7XfGB {
		position: static;
		position: initial;

		margin: 0;
		width: auto;
	}

	.startSearch-7XfGB button {
		width: 100%;
	}

	.passengers-2fsUu {
		max-width: none;
		margin-top: -1px;
		margin-left: 0;

		border-left: 1px solid var(--gray-line);
	}

	.passengers_isMultiCity-2aUwr {
		border-left: none;
	}

	.arrowIcon-3KYKS {
		display: inline-flex;
		margin-right: 20px;
	}

	.passengerAlternative-VfXBZ .selector-_BUhR {
		height: 52px;
	}

	.passengerAlternative-VfXBZ .passengerTitle-l2eVP {
		padding-left: 12px;

		text-align: left;
		font: var(--normal-desktop-accent);
	}

	.switcher-2o4os.switcher_alternative-1tGzq {
		border: 1px solid var(--gray-line);

		color: var(--text-pale);

		background: var(--white);
	}

	.month-_n9Xx {
		padding-right: 0;
	}

	.day_withPrice-AaGyT {
		width: 47px;
		height: 47px;
	}

	.dayOfWeekName_withPrices-3gtjx {
		width: 47px;
	}

	.day_today--eiUY {
		border-radius: 0;
		border: 1px solid var(--line-dot-line);
	}

	div.footer__buttons-2SHQo {
		right: 12px;
		bottom: 12px;
		left: 12px;

		width: auto;

		background: var(--brand-3-0);
	}

	.passengersMobileTitle-OfGwV {
		margin: 0 0 16px 0;
		padding-bottom: 18px;

		border-bottom: 1px solid var(--gray-line);

		color: var(--text-base);
		font-weight: 700;
	}

	.datepicker__disclaimer-2_QVb {
		position: fixed;
		top: 50px;

		display: none;
		margin: 20px 12px;
		padding: 15px 13px 15px 45px;

		border: 1px solid var(--gray-line);
		border-radius: 4px;

		color: var(--text-mid);
		font-weight: 600;
		font-size: 14px;
		line-height: 18px;

		background: #ffffff;
	}

	.datepicker__disclaimer-2_QVb:before {
		content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDEwQzAgNC40OCA0LjQ4IDAgMTAgMEMxNS41MiAwIDIwIDQuNDggMjAgMTBDMjAgMTUuNTIgMTUuNTIgMjAgMTAgMjBDNC40OCAyMCAwIDE1LjUyIDAgMTBaTTExIDlWMTVIOVY5SDExWk05Ljk5OTk5IDE4QzUuNTg5OTkgMTggMS45OTk5OSAxNC40MSAxLjk5OTk5IDEwQzEuOTk5OTkgNS41OSA1LjU4OTk5IDIgOS45OTk5OSAyQzE0LjQxIDIgMTggNS41OSAxOCAxMEMxOCAxNC40MSAxNC40MSAxOCA5Ljk5OTk5IDE4Wk0xMSA1VjdIOVY1SDExWiIgZmlsbD0iI0IzQjNCMyIvPgo8L3N2Zz4K);

		position: absolute;
		left: 13px;
		top: 53%;

		transform: translateY(-50%);
	}

	.popup-1Iwaa {
		padding-top: 60px;
	}

	.progress__root-174WX {
		top: 56px;
	}

	.alternative-i_Pub .dow-20q0j {
		display: inline;

		opacity: 1;
	}

	.alternative_dow-2z8qW {
		display: none;
	}

	.bottom-1VAmP > div:not(:last-of-type) {
		border-bottom: none;
	}

	.switchWrapper-y_f86 {
		padding: 0;
	}

	.multiCity__caption-2b6JX,
	.switchBox-1t7n6 {
		font: var(--medium--1-mobile);
	}

	.controls_multiCity-1adR7 {
		padding: 0;

		border-radius: 4px 4px 0 0;
	}

	button.removeSegment-kAvHS {
		margin-left: -1px;
	}

	button.addSegment-3p2O3,
	button.removeSegment-kAvHS {
		border: 1px solid var(--line-separator);

		color: var(--text-pale);

		background: var(--white);
	}
}
