.outer__summary-1Il5V {
	font: var(--medium--1-desktop-accent);

	background: var(--brand-1-5);
}

.summary-1Qj0N {
	padding: 12px 0;
	min-height: unset;
}

.summary__route-1inb0,
.summary__dates-3bB65,
.summary__passengers-2xZ8a {
	color: var(--text-base);
	font: var(--medium--1-desktop-accent);
}

.summary__dates-3bB65:before,
.summary__passengers-2xZ8a:before,
.summary__editButtonWrapper-2GRM0:before {
	background: var(--text-pale);
}

.summary__editButtonWrapper-2GRM0 button {
	align-items: center;
	padding: 0 4px;

	line-height: 1;

	background: transparent;
}

.summary__editButtonWrapper-2GRM0 button span {
	font: var(--medium--1-desktop-accent);
	color: var(--text-link);
	line-height: 1;

	background: transparent;
}

.summary__editButtonWrapper-2GRM0 button span:last-child {
	color: var(--text-pale);
}

.editButton__icon-2PFtu {
	color: var(--text-pale);

	background: transparent;
}

.wrapper-BsO6Z {
	background: var(--brand-1-5);
}

.form__wrapper-3qAZ1 {
	background: var(--brand-1-5);
}

.form-3ArrZ {
	background: var(--brand-1-5);
}

@media (max-width: 1024px) {
	.inner__summary-2Dqzh {
		padding: 12px 20px;
	}

	.summary-1Qj0N {
		padding: 12px 0;
		min-height: unset;
	}
}

@media screen and (max-width: 600px) {
	.inner__summary-2Dqzh {
		padding: 0 12px;

		color: var(--text-base);

		background: var(--bg-white);
	}
}
