

button.suggestionModal__button-2XwWA {
	min-width: 200px;

	font-weight: 600;
	color: var(--white);

	background: var(--brand-3-0);
}

button.suggestionModal__button-2XwWA:hover {
	background: var(--brand-3--1);
}

button.suggestionModal__button-2XwWA.suggestionModal__buttonDecline-X_bwQ {
	color: var(--brand-1-0);

	background: var(--brand-1-5);
}

button.suggestionModal__button-2XwWA.suggestionModal__buttonDecline-X_bwQ:hover {
	background: var(--brand-1-4);
}

.suggestionModal__text-1PbSR {
	color: var(--text-base);
}
