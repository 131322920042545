

button.root-3DDVf,
div.root-3DDVf {
	border-radius: 60px;
}

/* Btn / Small Neitral */
button.smallNeitral-3YrdD {
	border: 1px solid var(--bg-elements);

	color: var(--brand-1-0);

	background: var(--bg-elements);
}

button.smallNeitral-3YrdD:hover {
	border: 1px solid var(--brand-1-0);

	color: var(--brand-1-0);
}

button.smallNeitral-3YrdD:disabled,
button.smallNeitral_disabled-1LxwW:disabled {
	color: var(--text-pale);

	background-color: var(--bg-fill);
}

button.smallThird-1xvfN {
	color: var(--white);

	background: var(--brand-3-0);
}

button.smallThird-1xvfN:hover {
	background: var(--brand-3--1);
}

button.smallThird-1xvfN:active {
	background: var(--brand-3--2);
}

button.smallThird-1xvfN:disabled,
button.smallThird_disabled-3fFdV {
	color: var(--white);

	background: var(--brand-3-3);
}

/* Option / Edit */
button.optionEdit-37cj6 {
	color: var(--brand-1-0);
}

button.optionEdit-37cj6:hover {
	border-color: var(--bg-elements);

	background: var(--bg-elements);
}
