

button.base-3okvJ {
	min-width: auto;
	padding: 8px 16px;

	border-radius: 9999px;

	transition: color 0.15s, background 0.15s, border 0.15s;
}

/* Size */
button.small-fqb6C {
	padding: 10px 16px;

	font: var(--normal-desktop-accent);
}

button.large-2dJnB {
	padding: 12px 24px;

	font: var(--medium-desktop-accent);
}

/* Color */
button.primary-20kTc {
	border: none;

	color: var(--white);

	background: var(--brand-3-0);
}

button.primary-20kTc:hover {
	border: none;

	color: var(--white);

	background: var(--brand-3--1);
}

button.primary-20kTc:active {
	border: none;

	background: var(--brand-3--2);
}

button.primary-20kTc[disabled] {
	color: var(--text-pale);

	background: var(--bg-fill);
}

button.secondary-3WMiQ {
	color: var(--white);

	background: var(--brand-1-0);
}

button.secondary-3WMiQ:hover,
button.secondary-3WMiQ:active {
	color: var(--white);

	background: var(--brand-1--1);
}

button.secondary-3WMiQ[disabled] {
	color: var(--white);

	background: var(--brand-3-3);
}

button.neitral-2h3I7 {
	border: 1px solid var(--bg-elements);

	color: var(--brand-1-0);

	background: var(--bg-elements);
}

button.neitral-2h3I7:hover,
button.neitral-2h3I7:active {
	border-color: var(--brand-1-0);

	color: var(--brand-1-0);

	background: var(--bg-elements);
}

button.neitral-2h3I7[disabled] {
	color: var(--text-pale);

	background: var(--bg-fill);
}

button.outlined-nhv81 {
	border: 1px solid var(--brand-1-0);

	color: var(--brand-1-0);

	background: transparent;
}

button.outlined-nhv81:hover,
button.outlined-nhv81:active {
	border: 1px solid var(--bg-elements);

	color: var(--brand-1-0);

	background: var(--bg-elements);
}

button.outlined-nhv81[disabled] {
	color: var(--text-pale);

	background: var(--bg-elements);
}
