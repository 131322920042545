div div.paper-38eZl {
	display: flex;
	flex-direction: column;
	grid-gap: 16px;
	gap: 16px;
}

.header-1RKeZ {
	padding: 0;
}

.content-2fszx {
	padding: 0;
}

.footer-1oQEe {
	padding: 0;
}

.buttons-2Pe1O {
	padding: 0;
	justify-content: flex-end;
	align-items: center;
}

.buttons-2Pe1O button {
	flex-grow: 1;
	flex-basis: unset;
}

button.cancelButton-2tMZO {
	padding: 12px 24px;

	border-radius: 0;

	color: var(--text-light);

	background: var(--bg-fill);
}

button.cancelButton-2tMZO:hover {
	color: var(--text-light);

	background: var(--bg-elements);
}

.title-14Vs8 {
	width: calc(100% - 40px);
	display: flex;
	flex-direction: row;
	grid-gap: 16px;
	gap: 16px;
}

.title-14Vs8:before {
	content: '';

	display: block;
	width: 36px;
	min-width: 36px;
	height: 36px;

	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NCIgaGVpZ2h0PSI0NCIgdmlld0JveD0iMCAwIDQ0IDQ0IiBmaWxsPSJub25lIj4KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTMuNjY2NSAyMi4wMDA2QzMuNjY2NSAzMi4xMjA2IDExLjg3OTggNDAuMzM0IDIxLjk5OTggNDAuMzM0QzMyLjExOTggNDAuMzM0IDQwLjMzMzIgMzIuMTIwNiA0MC4zMzMyIDIyLjAwMDZDNDAuMzMzMiAxMS44ODA2IDMyLjExOTggMy42NjczMSAyMS45OTk4IDMuNjY3MzFDMTEuODc5OCAzLjY2NzMxIDMuNjY2NSAxMS44ODA2IDMuNjY2NSAyMi4wMDA2Wk0yMy44MzMyIDIzLjgzNFYxMi44MzRIMjAuMTY2NVYyMy44MzRIMjMuODMzMlpNMjEuOTk5OCA3LjMzMzk5QzEzLjkxNDggNy4zMzM5OSA3LjMzMzE2IDEzLjkxNTcgNy4zMzMxNiAyMi4wMDA3QzcuMzMzMTYgMzAuMDg1NyAxMy45MTQ4IDM2LjY2NzMgMjEuOTk5OCAzNi42NjczQzMwLjA4NDggMzYuNjY3MyAzNi42NjY1IDMwLjA4NTcgMzYuNjY2NSAyMi4wMDA3QzM2LjY2NjUgMTMuOTE1NyAzMC4wODQ4IDcuMzMzOTkgMjEuOTk5OCA3LjMzMzk5Wk0yMy44MzMyIDMxLjE2NzNWMjcuNTAwN0gyMC4xNjY1VjMxLjE2NzNIMjMuODMzMloiIGZpbGw9IiNCM0IzQjMiLz4KPC9zdmc+);
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

.contentWrapper-O4Xug {
	color: var(--text-light);
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: 1.3;
}
@media screen and (max-width: 600px) {
	.header-1RKeZ {
		padding: 0;
	}

	.title-14Vs8 {
		width: 100%;

		display: flex;
		flex-direction: column;
		align-items: center;
		flex-grow: 1;

		text-align: center;
	}

	.title-14Vs8:before {
		width: 44px;
		height: 44px;
	}

	.content-2fszx {
		padding: 0;
	}

	.footer-1oQEe {
		padding: 0;
	}

	.buttons-2Pe1O button {
		flex-grow: 1;
	}
}
