.retrieveBooking__container-1ybPA {
	grid-gap: 20px;
	gap: 20px;
}

.retrieveBooking__container_checkin-32ASV .retrieveBooking__title-kdEoR {
	display: inline;
	display: initial;

	color: var(--text-base);
	font-size: 22px;
	font-weight: 600;
	line-height: 1.3;
}

.retrieveBooking__button-1JYqc {
	background: var(--brand-3-0);
}

button.retrieveBooking__button-1JYqc {
	font-weight: 600;
}

.retrieveBooking__form-ggWih {
	grid-gap: 20px;
	gap: 20px;
}

@media screen and (max-width: 600px) {
	.retrieveBooking__form-ggWih {
		margin-top: 0;
	}
}
