

.container-D_zP4 {
	display: flex;
	align-items: center;
	grid-gap: 4px;
	gap: 4px;
	height: 56px;
}

label.element-39kxw {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;

	border-color: var(--bg-elements);

	color: var(--brand-1-0);

	background: var(--bg-elements);
}

label.element-39kxw > input {
	pointer-events: none;
}

label.element-39kxw:first-of-type,
label.element-39kxw:last-of-type {
	padding: 0;

	border-radius: 5px;
}

label.element-39kxw:first-of-type > svg,
label.element-39kxw:last-of-type > svg {
	position: relative;
	top: auto;
	left: auto;
	right: auto;
}

label.element-39kxw.element_active-31ZrO {
	border-color: var(--brand-1-0);

	color: var(--white);

	background: var(--brand-1-0);
}

label.element-39kxw.element_error-nBgor {
	border-color: var(--error-stroke-hover);

	color: var(--error-icon);

	background: var(--error-bg);
}

label.element-39kxw + label.element-39kxw {
	margin-left: 0;
}

.switch__wrapper-2gcM7.switch__active-1Uxjh:after {
	background: var(--brand-3-0);
}
