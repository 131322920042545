

div.transfer-3nxqq {
	display: flex;
	flex-direction: column;

	border-radius: 12px;
}

.warning-1gEFq {
	margin-top: 0;
	margin-left: 0;
}
